import { createAsyncThunk } from "@reduxjs/toolkit";
import { firebaseDB, firebaseApp } from "~src/config/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  startAfter,
  startAt,
  endAt,
} from "firebase/firestore";

import { setLoading } from "../progress/progressSlice";
import { sleep } from "~src/utils";

export const fetchAccomodationDetailAction = createAsyncThunk(
  "seek/fetchAccomodationDetailAction",
  async ({ accomodationUid }, { dispatch, rejectWithValue, getState }) => {
    dispatch(setLoading(true));
    let snapshot;
    try {
      await sleep(1000);

      snapshot = await getDocs(
        query(
          collection(firebaseDB, "accomodations"),
          where("__name__", "==", accomodationUid),
          where("settings.enabled", "==", true)
        )
      );

      const [accomodation] = snapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      if (!accomodation) {
        return null;
      }

      snapshot = await getDocs(
        query(collection(firebaseDB, "seekers"), where("__name__", "==", accomodation._.userUid))
      );

      const [advertiser] = snapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      accomodation.advertiser = advertiser;

      return accomodation;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const fetchAccomodationDetailExtraReducer = (builder) => {
  builder.addCase(fetchAccomodationDetailAction.fulfilled, (state, action) => {
    console.log("fetchAccomodationDetailAction fulfilled");

    state.accomodationDetail = action.payload;
  });

  builder.addCase(fetchAccomodationDetailAction.rejected, (state, action) => {
    console.log("fetchAccomodationDetailAction rejected");
    throw action.payload;
  });
};
