import { createAsyncThunk } from "@reduxjs/toolkit";
import { firebaseDB, firebaseApp } from "~src/config/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  startAfter,
  startAt,
  endAt,
} from "firebase/firestore";

import { setLoading } from "../progress/progressSlice";

export const fetchAccomodationDetailByUidsByUidsAction = createAsyncThunk(
  "seek/fetchAccomodationDetailByUidsByUidsAction",
  async ({ uids }, { dispatch, rejectWithValue, getState }) => {
    dispatch(setLoading(true));
    let snapshot;
    try {
      snapshot = await getDocs(
        query(
          collection(firebaseDB, "accomodations"),
          where("__name__", "in", uids?.length ? uids : ["-1"]),
          where("settings.enabled", "==", true)
        )
      );

      const accomodations = snapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      // snapshot = await getDocs(
      //   query(collection(firebaseDB, "seekers"), where("__name__", "==", accomodation._.userUid))
      // );

      // const [advertiser] = snapshot.docs.map((doc) => {
      //   return {
      //     ...doc.data(),
      //     id: doc.id,
      //   };
      // });

      // accomodation.advertiser = advertiser;

      return accomodations;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const fetchAccomodationDetailByUidsExtraReducer = (builder) => {
  builder.addCase(fetchAccomodationDetailByUidsByUidsAction.fulfilled, (state, action) => {
    // console.log("fetchAccomodationDetailByUidsByUidsAction fulfilled");
    state.accomodationsDetail = action.payload;
  });

  builder.addCase(fetchAccomodationDetailByUidsByUidsAction.rejected, (state, action) => {
    throw action.payload;
  });
};
