export default {
  loginScreenEmailPassword: "Вход",
  loginEmailPwButton: "Войти с помощью электронной почты и пароля",
  email: "Электронная почта",
  loginButton: "Войти",
  password: "Пароль",
  createAccount: "Создать аккаунт",
  forgotPassword: "Забыли пароль",
  resetPasswordButton: "Сбросить пароль",
  resetPasswordScreen: "Забыли пароль",
  resetPasswordMessage: "Ссылка для сброса пароля была отправлена на ваш электронный адрес",
  providedEmailWasNotFound: "Указанная электронная почта не найдена",
  loginFailed: "Не удалось войти",
  emailExists: "Этот аккаунт уже существует",
  invalidEmailAddress: "Неверный адрес электронной почты",
  invalidPassword: "Пароль должен содержать не менее 6 символов",
  verificationSent: "Письмо с подтверждением отправлено. Пожалуйста, проверьте свою почту и подтвердите регистрацию.",
  accountInactive: "Ваш аккаунт все еще неактивен",
  privacyPolicyButton: "Прочитайте нашу Политику конфиденциальности",
  unknown: "Неизвестный пользователь",
  youMustBeLoggedIn: "Вы должны войти",
  
  aboutUs: 'apartseeker.com',
  seekDetailScreenTitle: "Детали Размещения",
  deleteTopicMessage: "Вы хотите удалить этот разговор со всеми его сообщениями?",
  chatScreenTitle: "Сообщения",
  seekListScreenTitle: "Исследовать",
  seek: "Исследовать",
  seekAndEnjoy: "",
  emailVisible: "Электронная почта публичная",
  firstName: "Имя",
  lastName: "Фамилия",
  home: "Домой",
  welcome: "Добро пожаловать",
  myAds: "Мои Объявления",
  myAparts: "Апартаменты",
  myApartsShort: "Апт.",
  login: "Войти",
  tests: "Тесты",
  examples: "Примеры",
  searchAddress: "Искать Адрес",
  save: "Сохранить",
  cancel: "Отмена",
  back: "Назад",
  delete: "Удалить",
  forward: "Вперед",
  select: "Выбрать",
  profileAdvSettings: "Настройки Профиля Рекламодателя",
  validationMinus: "Значение не может быть отрицательным",
  validation0100: "Значение должно быть между 0 и 100",
  validationPrice1: "Цена обязательна",
  validationPrice2: "Цена введена неверно",
  validationRequired: "Это поле обязательно для заполнения",
  validationPrevValue: "Должно быть больше, чем {{value}}",
  created: "Создано",
  updated: "Обновлено",
  loginFacebook: "Войти через Facebook",
  profileNormalMenu: "Настройки",
  profileAdvMenu: "Для Рекламодателей",
  profileScreenTitle: "Настройки",
  profileAdvScreenTitle: "Профиль Рекламодателя",
  AdvertiserMenu: "Для Рекламодателей",
  account: "Аккаунт",
  profile: "Профиль",
  explore: "Исследовать",
  
  iAmAdvertiser: "Я Рекламодатель",
  approveTerms: "Я прочитал и принимаю условия использования",
  approveTermsDesc: "Ваши объявления не будут отображаться, пока вы не примете условия.",
  availableAccomodations: "Вы можете опубликовать ещё %{value} объявлений",
  noAvailableAccomodations: "Вы не можете опубликовать больше объявлений",
  approveTermsIsRequired: "Для публикации объявления примите условия использования.",
  search: "Поиск",
  searchScreenTitle: "Поиск",

  searchByAddress: "Адрес",
  searchByMap: "Карта",
  searchFavourites: "Избранное",
  searchHistory: "История",
  logout: 'Выйти',
  deleteSeeker: "Удалить Мой Аккаунт",
  areYouSureDeleteSeeker: "Ваш аккаунт и все связанные данные будут удалены. Вы уверены, что хотите продолжить?", 
  areYouSureDeleteAccomodation: "Ваше объявление будет удалено. Вы уверены, что хотите продолжить?", 
  deleteAccomodation: "Удалить Объявление",
  pageNotFoundTitle: "404",
  pageNotFoundText: "То, что вы ищете, в данный момент недоступно, пожалуйста, вернитесь позже.",
  emptyListText: "Нет элементов для отображения",
  seekListEmptyText: "В этом районе пока нет жилья.",
  seekListEmptyButtonText: "Если вы хотите разместить объявление, сделайте это здесь",
  chatEmptyText: "У вас ещё нет сообщений. Свяжитесь с рекламодателями! Вы можете сделать это легко с помощью значка в объявлениях.",
  signupDesc:
    "Для публикации вашего объявления вам необходимо принять условия использования.",
  advPackages: {
    myPackage: "Мой Пакет",
    general: "В настоящее время доступен только базовый рекламный пакет",
    maxAccomodation: "Количество объявлений, которые можно опубликовать %{value}",
    maxItem: "Количество объявлений, которые можно опубликовать %{value}",
    maxImagesPerCategory: "Общее количество изображений, которые можно загрузить %{value}",
    maxPriceRules: "Количество применимых ценовых правил %{value}",
    durationDay: "Действует %{value} дней, можно продлить бесплатно",
    default: {
      title: "Базовый Пакет",
      price: "Бесплатно", 
    },
  },
  accomodationDefaultPackageName: "Базовый Рекламный Пакет",
  phone: "Телефон",
  language: "Язык",
  appLanguage: "Язык Приложения",
  en: "Английский",
  de: "Немецкий",
  es: "Испанский",
  fr: "Французский",
  cz: "Чешский",
  hu: "Венгерский",
  ru: "Русский",
  settings: "Настройки",
  languages: "Говоримые Языки",
  pricing: {
    regardlessGuestCount: "независимо от количества гостей",
    byNight: "/ ночь",
    night: "ночь",
    nightFrom: "от ночи",
    pricePerNight: "цена за ночь",
    guest: "гость",
    guestCountLabel1: "%{value} гость",
    guestCountLabel2: "от %{value} гостей",
    nightCountLabel1: "%{value} ночь",
    nightCountLabel2: "от %{value} ночей",
    nightCountLabel3: "от %{value} ночей",
    cleaningPrice: "Стоимость Уборки",
  },
  apartAccomodation: {
    basePriceSettings: "Основные Настройки",
    pricingRulesDelete: "Удалить",
    pricingRules: "Ценовые Правила",
    volumeDiscountPricing: "Скидка за Объем",
    guestDiscountPricing: "Скидка за Количество Гостей",
    discount: "Скидка",
    cleaningPrice: "Стоимость Уборки",
    reservationLength: "Длительность Бронирования",
    night: "Ночь",
    nightFrom: "От Ночи",
    pricePerNight: "Цена за Ночь",
    guest: "Гость",
    currency: "Валюта",
    pricing: "Настройки Цен",
    pricingTemplate: "Ежедневные Цены",
    priceVisible: "Цены Опубликованы",
    noBedroom: "Нет Спальни",
    noLivigingroom: "Нет Гостиной",
    noBathroom: "Нет Ванной",
    created: "Создано",
    updated: "Обновлено",
    settings: "Настройки",
    finalization: "Завершение",
    enabled: "Опубликовано",
    updated: "Обновлено",
    deleted: "Удалено",
    nextToImages: "Далее",
    add: "Добавить Новое Объявление",
    modify: "Изменить Объявление",
    address: "Адрес Жилья",
    name: "Название Жилья",
    description: "Описание",
    accomodationType: "Тип Жилья",
    house: "Дом",
    apartment: "Квартира",
    room: "Комната",
    livingRoom: "Гостиная",
    kitchen: "Оборудованная Кухня",
    wifi: "Wi-Fi",
    hairDryer: "Фен",
    tv: "Телевизор",
    amenities: "Удобства",
    services: "Сервисы",
    features: "Особенности",
    pool: "Бассейн",
    freeParking: "Бесплатная Парковка",
    gym: "Тренажерный Зал",
    location: "Расположение",
    beachfront: "На Берегу Моря",
    beachNearby: "Рядом с Пляжем",
    restaurantsNearby: "Рядом с Ресторанами",
    bustopNearby: "Рядом с Автобусной Остановкой",
    aiportNearby: "Рядом с Аэропортом",
    shopsNearby: "Рядом с Магазинами",
    balcony: "Балкон",
    airConditioner: "Кондиционер",
    seeview: "Вид на Море",
    lakeside: "На Берегу Озера",
    mountainview: "Вид на Горы",
    downtown: "Центр Города",
    countryside: "Сельская Местность",
    forestEdge: "На Краю Леса",
    golfCourseAdjacent: "Поле для Гольфа",
    massage: "Массаж",
    sauna: "Сауна",
    pool: "Бассейн",
    heatedPool: "Подогреваемый Бассейн",
    lift: "Лифт",
    ground: "Первый Этаж",
    barrierFree: "Без Барьеров",
    smartTv: "Smart TV",
    netflix: "Netflix",
    bedRoom: "Спальни",
    bed: "Кровать",
    bedroom: "Спальня",
    bathroom: "Ванная",
    studio: "Студия",
    images: "Изображения",
    addImages: "Добавить Изображения",
    breakfastIncluded: "Завтрак Включен",
    lunchIncluded: "Обед Включен",
    dinnerIncluded: "Ужин Включен",
    freeTransfer: "Бесплатный Трансфер из Аэропорта",
    babyBed: "Детская Кроватка",
    petsAllowed: "Разрешены Домашние Животные",
    welcomeDrink: "Приветственный Напиток",
    imageCategories: {
      rooms: "Комнаты",
      bathRoom: "Ванная",
      kitchen: "Кухня",
      balkony: "Балкон",
      outdoor: "Наружный",
    },
  },
  validation: {
    required: "Обязательное Поле",
    selectionIsRequired: "Требуется Выбор",
  },
  typeMessage: "Сообщение...",
};
