import { Text, IconButton } from "react-native";
import { collectPaths, getValueFromPath, getNodeByPath, qStringToObj } from "~src/utils";
// import { this._i18n } from "~src/translation";
import { View, Image, StyleSheet, Platform } from "react-native";
import { theme as myColors } from "~src/styles/theme";
import DisplayFactory from "./DisplayFactory";
import uniq from "lodash/uniq";
import DividerLine from "~src/components/DividerLine";
import { initialPayload, payload } from "~src/payloads/payload";
import { payloadAccomodation } from "~src/payloads/accomodation";
import { dateToMMMMDHHmm, dateToDisplay } from "~src/utils";
import { Badge, Icon } from "../styled-components";

class DisplayFactoryAccomodation extends DisplayFactory {
  singleByPath({ path, containerStyle }) {
    const node = getNodeByPath(this._collection, path);
    const value = getValueFromPath(this._item, path);
    const { type, maxLength } = qStringToObj(node.config.qString);
    const item = {
      key: node.name,
      display: value > 0,
      value: value,
      text: `${value} ${this._i18n.t(`${this._i18Prefix}${node.config.i18}`)}`,
      icon: node.config.icon ?? "check",
    };

    return this.renderItem({ item, containerStyle });
  }

  renderItem({ item, containerStyle }) {
    return (
      item.display && (
        <View
          key={item.key}
          style={[
            {
              flexDirection: "row",
              alignItems: "center",
            },
            containerStyle,
          ]}
        >
          {item.icon && (
            <View style={{ marginRight: 4 }}>
              <Icon source={item.icon} size={15} color={myColors.primary} />
            </View>
          )}
          <Text
            variant="bodyMedium"
            style={{ marginRight: 10 /*, width: width / 4*/ }}
            numberOfLines={1}
          >
            {item.text}
          </Text>
        </View>
      )
    );
  }

  multiByPath({ path, limit, containerStyle }) {
    const node = getNodeByPath(this._collection, path);
    const value = getValueFromPath(this._item, path);

    const arr = node.config.range
      .filter(({ option }, index) => {
        return value?.includes(option) && (limit ? index < limit : true);
      })
      .map((configItem, index) => {
        return {
          key: index,
          display: true,
          text: this._i18n.t(`${this._i18Prefix}${configItem.i18}`),
          icon: configItem.icon ?? "check",
        };
      });

    return arr.map((item) => this.renderItem({ item, containerStyle }));
  }

  address(params = {}) {
    const { variant = null, style } = params;
    const { address_components, formatted_address } = this._item?.basic?.address ?? {};
    const result = [];

    const country = address_components?.find((item) => {
      return item.types[0] === "country";
    });

    const locality = address_components?.find((item) => {
      return item.types[0] === "locality";
    });

    const administrative_area_level_1 = address_components?.find((item) => {
      return item.types[0] === "administrative_area_level_1";
    });

    if (country) {
      result.push(country.long_name);
    }

    if (administrative_area_level_1) {
      result.push(administrative_area_level_1.long_name);
    }

    if (locality) {
      result.push(locality.long_name);
    }

    return (
      <Text numberOfLines={2} variant={variant ?? "bodyMedium"} style={style}>
        {formatted_address ?? result.join(", ")}
      </Text>
    );
  }

  name(params = {}) {
    const { variant = null, style, clean } = params;
    const value = this._item?.basic?.name;

    if (clean) {
      return value;
    }

    return value;
  }

  _processPlainPrices = ({ pricing }) => {
    const result = {};
    pricing?.prices.forEach((priceObj) => {
      if (!result[priceObj.minNight]) {
        result[priceObj.minNight] = [];
      }
      result[priceObj.minNight].push({ price: priceObj.price, minClient: 1 });
      priceObj?.clientRules.forEach((clientRuleObj) => {
        result[priceObj.minNight].push({
          price: clientRuleObj.price,
          minClient: clientRuleObj.minClient,
        });
      });
    });

    return result;
  };

  _priceTagsNight = (props = {}) => {
    const { styleTagNight } = props;
    const { pricing } = this._item;
    const plainPrices = this._processPlainPrices({ pricing });
    const minNights = Object.keys(plainPrices);
    const result = [];
    let label;

    minNights.forEach((minNight, index) => {
      const nextItem = minNights[index + 1] ?? null;
      const minNightLabelArr = uniq([minNight * 1, nextItem ? (nextItem - 1) * 1 : null]);

      if (minNightLabelArr.length === 2 && !minNightLabelArr[1]) {
        label = this._i18n.t("pricing.nightCountLabel2", { value: minNightLabelArr.join("") });
      } else {
        label = this._i18n.t("pricing.nightCountLabel1", { value: minNightLabelArr.join(" - ") });
      }

      result.push(
        <View key={index}>
          <View
            style={[
              { backgroundColor: myColors.tabBarColor, alignItems: "center", borderRadius: 5 },
              styleTagNight,
            ]}
          >
            <Text variant="titleSmall">{label}</Text>
          </View>

          {this._priceTagsGuest({ pricesByNight: plainPrices[minNight] })}
        </View>
      );
    });

    return result;
  };

  _calculateDiscountPrice({ price, discount }) {
    return discount > 0 ? Math.round(price * (1 - (discount * 1) / 100)) : null;
  }

  _priceTagsGuest = ({ pricesByNight }) => {
    const result = [];
    const {
      pricing: { currency, discount },
    } = this._item;
    let label;

    pricesByNight?.forEach((priceObj, index) => {
      const { price } = priceObj;
      const discountPrice = this._calculateDiscountPrice({ price, discount });
      const nextItem = pricesByNight[index + 1] ?? null;
      const minClientLabelArr = uniq([
        priceObj.minClient * 1,
        nextItem ? (nextItem.minClient - 1) * 1 : null,
      ]);
      const variant = "bodyMedium";

      if (pricesByNight.length === 1) {
        label = this._i18n.t("pricing.regardlessGuestCount");
      } else if (minClientLabelArr.length === 2 && !minClientLabelArr[1]) {
        label = this._i18n.t("pricing.guestCountLabel2", { value: minClientLabelArr.join("") });
      } else {
        label = this._i18n.t("pricing.guestCountLabel1", { value: minClientLabelArr.join(" - ") });
      }

      result.push(
        <View key={index} style={{ flexDirection: "row", justifyContent: "center" }}>
          <Text variant={variant} style={[styles.priceText, { flex: 1 }]}>
            {label}
          </Text>
          <View style={[{ flexDirection: "row" }]}>
            <Text
              variant={variant}
              style={[
                styles.priceText,
                discountPrice ? styles.strikethroughPrice : styles.priceStyle,
              ]}
            >
              {price}
            </Text>
            <Text
              variant={variant}
              style={[
                styles.priceText,
                discountPrice ? styles.strikethroughPrice : styles.priceStyle,
              ]}
            >
              {currency}
            </Text>
            {!discountPrice && (
              <Text variant={variant} style={[styles.priceText, styles.priceStyle]}>
                {this._i18n.t("pricing.byNight")}
              </Text>
            )}
          </View>

          {discountPrice && (
            <View style={{ flexDirection: "row" }}>
              <Text variant={variant} style={[styles.priceText, styles.priceStyle]}>
                {discountPrice}
              </Text>
              <Text
                variant={variant}
                style={[styles.priceText, discountPrice ? styles.priceStyle : {}]}
              >
                {currency}
              </Text>
              <Text variant={variant} style={[styles.priceText, styles.priceStyle]}>
                {this._i18n.t("pricing.byNight")}
              </Text>
            </View>
          )}
          {discountPrice && (
            <Text variant={variant} style={[styles.priceText, styles.priceStyle]}>
              (-{discount}%)
            </Text>
          )}
        </View>
      );
    });

    return result.map((item) => item);
  };

  pricingDetailed(params = {}) {
    const { style, styleTagNight } = params;
    const variant = "bodyMedium";
    if (!this._item?.pricing) {
      return null;
    }
    const {
      pricing: { currency, cleaningPrice },
    } = this._item;

    return (
      <View style={style}>
        {this._priceTagsNight({ styleTagNight }).map((item) => item)}

        {cleaningPrice > 0 && (
          <>
            <DividerLine
              style={{
                height: 1,
                backgroundColor: myColors.panelBackground,
                marginVertical: 10,
              }}
            />

            <View style={{ flexDirection: "row" }}>
              <Text variant={variant} style={[styles.priceText, { flex: 1 }]}>
                {this._i18n.t("pricing.cleaningPrice")}
              </Text>
              <Text variant={variant} style={[styles.priceText, styles.priceStyle]}>
                {cleaningPrice}
              </Text>
              <Text variant={variant} style={[styles.priceText, styles.priceStyle]}>
                {currency}
              </Text>
            </View>
          </>
        )}
      </View>
    );
  }

  description(params = {}) {
    const { style } = params;
    const value = this._item?.advanced?.description;

    return <Text style={style}>{value}</Text>;
  }

  dateInserted = (prop = {}) => {
    return (
      <View style={{ flexDirection: "row" }}>
        <Text variant="bodySmall">{this._i18n.t("created")} </Text>
        <Text variant="bodySmall">{dateToMMMMDHHmm(this._item._.dateInserted)}</Text>
      </View>
    );
  };

  dateUpdated = (prop = {}) => {
    return (
      <View style={{ flexDirection: "row" }}>
        <Text variant="bodySmall">{this._i18n.t("updated")} </Text>
        <Text variant="bodySmall">{dateToMMMMDHHmm(this._item._.dateUpdated)}</Text>
      </View>
    );
  };

  state = () => {
    const { settings, _ } = this._item;
    const enabled = settings?.enabled ?? null;

    return (
      <Icon
        source={enabled ? "play" : "pause-circle"}
        color={enabled ? myColors.success : myColors.backdrop}
        size={20}
      />
    );
  };

  indexImage = (prop = {}) => {
    const { imageStyle } = prop;
    const indexImageUrl = this.indexImageUrl();

    return (
      indexImageUrl && (
        <Image
          source={{ uri: indexImageUrl }}
          style={[
            {
              aspectRatio: 4 / 3,
              width: 100,
            },
            imageStyle,
          ]}
        />
      )
    );
  };

  indexImageUrl = () => {
    const [imageItem] = this.allImageItems();

    // console.log(this.allImageItems())
    // console.log(JSON.stringify({imageItem}, true, 2))

    return imageItem?.downloadUrl ?? null;
  };

  renderColumnBox = (prop) => {
    const { items, col, containerStyle, lineStyle, itemStyle, maxItem } = prop;
    const width = `${100 / col}%`;

    return (
      <View style={containerStyle}>
        <View
          style={[
            {
              flexDirection: "row",
              flexWrap: "wrap",
            },
            lineStyle,
          ]}
        >
          {items
            .filter((item, index) => {
              return item && (maxItem ? index < maxItem : true);
            })
            .map((item, index) => {
              return (
                <View key={index} style={[{ width }, itemStyle]}>
                  {item}
                </View>
              );
            })}
        </View>
      </View>
    );
  };

  allImageItems = (props = {}) => {
    const limit = props?.limit ?? 0;
    const imageItems = [];
    if (this._item?.media?.images) {
      const { images } = this._item.media;
      collectPaths(this._collection, "images", "media").forEach(({ name }) => {
        if (images[name]) {
          images[name].forEach((imageItem) => {
            imageItems.push({
              category: name,
              ...imageItem,
            });
          });
        }
      });
    }

    imageItems.sort((a, b) => {
      if (a.indexImage === b.indexImage) {
        return 0;
      }
      return a.indexImage ? -1 : 1;
    });

    // console.log(JSON.stringify({imageItems}, null, 2))

    return limit > 0 ? imageItems.slice(0, limit) : imageItems;
  };

  slideShowImageItems = (props = {}) => {
    const limit = props?.limit ?? 0;
    const result = [];
    const resultByCategory = [];
    let cat = null;
    this.allImageItems().forEach((item) => {
      if (cat !== item.category) {
        if (!limit || resultByCategory.length < limit) {
          resultByCategory.push(item);
        }
        cat = item.category;
      }
      if (!limit || result.length < limit) {
        result.push(item);
      }
    });

    if (limit) {
      return resultByCategory.length >= limit ? resultByCategory : result;
    }

    return resultByCategory;
  };

  pricingSimple(params = {}) {
    const { style } = params;
    const variant = params.variant ?? "bodyMedium";
    const {
      pricing: { currency, cleaningPrice, discount, prices },
    } = this._item;
    const [firstPrice] = prices;
    const discountPrice = this._calculateDiscountPrice({ discount, price: firstPrice.price });

    const priceLabel = !discountPrice ? (
      <Text variant={variant} style={[styles.pricingSimple.priceStyle]}>
        {firstPrice.price}
      </Text>
    ) : (
      <Text variant={variant} style={[styles.pricingSimple.priceStyle]}>
        {discountPrice}
      </Text>
    );

    return (
      <View style={[{ position: "relative" }, style]}>
        <View style={styles.pricingSimple.priceTagContainer}>
          <View style={styles.pricingSimple.priceTagLeft}>
            <Text variant={variant}>
              {this._i18n.t("pricing.nightCountLabel3", { value: firstPrice.minNight })}
            </Text>
          </View>
          <View style={styles.pricingSimple.priceTagRight}>
            {priceLabel}
            <Text variant={variant} style={{ color: "white" }}>
              {currency}
            </Text>
          </View>
        </View>
        {discountPrice && (
          <Badge>
            <Icon source={"percent"} size={12} color={myColors.titleColor} />
          </Badge>
        )}
      </View>
    );
  }

  pricingMap(params = {}) {
    const { style, focused } = params;
    const variant = params.variant ?? "bodySmall";
    const {
      pricing: { currency, discount, prices },
    } = this._item;
    const [firstPrice] = prices;
    const discountPrice = this._calculateDiscountPrice({ discount, price: firstPrice.price });

    return (
      <View
        style={[
          styles.pricingMap.priceTag,
          focused ? styles.pricingMap.priceTagFocused : null,
          style,
        ]}
      >
        <Text
          variant={variant}
          style={[styles.pricingMap.priceText, focused ? styles.pricingMap.priceTextFocused : null]}
        >
          {discountPrice ?? firstPrice.price} {currency}
        </Text>
        {/* {discountPrice && (
          <Badge>
            <Icon source={"percent"} size={12} color={myColors.titleColor} />
          </Badge>
        )} */}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  nightText: {
    color: myColors.titleColor,
    backgroundColor: "red",
  },
  priceText: {
    marginRight: 3,
  },
  priceStyle: {
    color: myColors.titleColor,
  },
  strikethroughPrice: {
    textDecorationLine: "line-through",
    color: myColors.backdrop,
  },
  pricingSimple: {
    strikethroughPrice: {
      textDecorationLine: "line-through",
      fontSize: 12,
    },
    priceStyle: { color: "white", fontWeight: "bold" },
    priceTagContainer: {
      // marginLeft: 10,
      flexDirection: "row",
      backgroundColor: "white",
      borderRadius: 10,
      ...Platform.select({
        ios: {
          shadowColor: "#000",
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.5,
          shadowRadius: 2,
        },
        android: {
          elevation: 2,
        },
      }),
    },
    priceTagLeft: {
      justifyContent: "center",
      alignItems: "center",
      paddingHorizontal: 10,
    },
    priceTagRight: {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      overflow: "hidden",
      paddingHorizontal: 10,
      paddingVertical: 3,
      backgroundColor: myColors.titleColor,
      // backgroundColor: myColors.primaryContainer,
      // justifyContent: 'flex-start',
      alignItems: "center",
    },
    container: {
      flex: 1,
      position: "absolute",
      bottom: 10,
      right: 10,
    },
  },
  pricingMap: {
    priceTag: {
      paddingHorizontal: 3,
      borderRadius: 5,
      backgroundColor: "rgba(10, 10, 10, 0.5)",
    },
    priceTagFocused: {
      backgroundColor: myColors.titleColor,
    },
    priceText: {
      color: "#fff",
      fontSize: 10,
    },
    priceTextFocused: {
      fontSize: 12,
      fontWeight: "bold",
    },
  },
});

// const stylesPricingSimple = StyleSheet.create();

export default DisplayFactoryAccomodation;
