export default {
  loginScreenEmailPassword: "Anmeldung",
  loginEmailPwButton: "Mit E-Mail und Passwort anmelden",
  email: "E-Mail",
  loginButton: "Anmelden",
  password: "Passwort",
  createAccount: "Konto erstellen",
  forgotPassword: "Passwort vergessen",
  resetPasswordButton: "Passwort zurücksetzen",
  resetPasswordScreen: "Passwort vergessen",
  resetPasswordMessage: "Ein Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail gesendet",
  providedEmailWasNotFound: "Die angegebene E-Mail wurde nicht gefunden",
  loginFailed: "Anmeldung fehlgeschlagen",
  emailExists: "Dieses Konto existiert bereits",
  invalidEmailAddress: "Ungültige E-Mail-Adresse",
  invalidPassword: "Das Passwort muss mindestens 6 Zeichen lang sein",
  verificationSent: "Eine Bestätigungs-E-Mail wurde gesendet. Bitte überprüfen Sie Ihre E-Mail und bestätigen Sie Ihre Registrierung.",
  accountInactive: "Ihr Konto ist noch inaktiv",
  privacyPolicyButton: "Lesen Sie unsere Datenschutzrichtlinie",
  unknown: "Unbekannter Benutzer",
  youMustBeLoggedIn: "Sie müssen eingeloggt sein, um auf diese Funktion zuzugreifen.",
  youMustBeLoggedInToPost: "Sie müssen eingeloggt sein, um eine Anzeige zu posten.",
  
  aboutUs: "apartseeker.com",
  seekDetailScreenTitle: "Unterkunft Details",
  deleteTopicMessage: "Möchten Sie dieses Gespräch mit allen Nachrichten löschen?",
  chatScreenTitle: "Nachrichten",
  seekListScreenTitle: "Erkunden",
  seek: "Erkunden",
  seekAndEnjoy: "",
  emailVisible: "E-Mail Öffentlich",
  firstName: "Vorname",
  lastName: "Nachname",
  home: "Startseite",
  welcome: "Willkommen",
  myAds: "Meine Anzeigen",
  myAparts: "Apartments",
  myApartsShort: "Apt.",
  login: "Anmelden",
  tests: "Tests",
  examples: "Beispiele",
  searchAddress: "Adresse Suchen",
  save: "Speichern",
  cancel: "Abbrechen",
  back: "Zurück",
  delete: "Löschen",
  forward: "Weiter",
  select: "Auswählen",
  profileAdvSettings: "Einstellungen für Werbeprofile",
  validationMinus: "Der Wert darf nicht negativ sein",
  validation0100: "Der Wert muss zwischen 0 und 100 liegen",
  validationPrice1: "Der Preis ist erforderlich",
  validationPrice2: "Der Preis ist falsch eingegeben",
  validationRequired: "Dieses Feld ist erforderlich",
  validationPrevValue: "Muss größer sein als {{value}}",
  created: "Erstellt",
  updated: "Aktualisiert",
  loginFacebook: "Mit Facebook anmelden",
  profileNormalMenu: "Einstellungen",
  profileAdvMenu: "Für Werbetreibende",
  profileScreenTitle: "Einstellungen",
  profileAdvScreenTitle: "Werbeprofil",
  AdvertiserMenu: "Für Werbetreibende",
  account: "Konto",
  profile: "Profil",
  explore: "Erkunden",

  iAmAdvertiser: "Ich bin Werbetreibender",
  approveTerms: "Ich habe die Nutzungsbedingungen gelesen und akzeptiere sie",
  approveTermsDesc: "Ihre Anzeigen werden erst angezeigt, wenn Sie die Bedingungen akzeptieren.",
  availableAccomodations: "Sie können noch %{value} Anzeigen veröffentlichen",
  noAvailableAccomodations: "Sie können keine weiteren Anzeigen veröffentlichen",
  approveTermsIsRequired:
    "Um eine Anzeige zu veröffentlichen, akzeptieren Sie die Nutzungsbedingungen.",
  search: "Suche",
  searchScreenTitle: "Suche",

  searchByAddress: "Adresse",
  searchByMap: "Karte",
  searchFavourites: "Favoriten",
  searchHistory: "Verlauf",
  logout: "Abmelden",
  deleteSeeker: "Mein Konto löschen",
  areYouSureDeleteSeeker:
    "Ihr Konto und alle zugehörigen Daten werden gelöscht. Sind Sie sicher, dass Sie fortfahren möchten?",
  areYouSureDeleteAccomodation:
    "Ihre Anzeige wird gelöscht. Sind Sie sicher, dass Sie fortfahren möchten?",
  deleteAccomodation: "Anzeige löschen",
  pageNotFoundTitle: "404",
  pageNotFoundText: "Was Sie suchen, ist derzeit nicht verfügbar. Bitte kommen Sie später wieder.",
  emptyListText: "Keine Elemente zum Anzeigen",
  seekListEmptyText: "In diesem Bereich gibt es noch keine Unterkünfte.",
  seekListEmptyButtonText: "Wenn Sie werben möchten, geben Sie hier eine Anzeige auf",
  chatEmptyText:
    "Sie haben noch keine Nachrichten. Kontaktieren Sie die Inserenten! Dies können Sie ganz einfach über das Symbol in den Anzeigen tun.",
  signupDesc: "Um Ihre Anzeige zu veröffentlichen, müssen Sie die Nutzungsbedingungen akzeptieren.",
  advPackages: {
    myPackage: "Mein Paket",
    general: "Derzeit ist nur das Basiswerbepaket verfügbar",
    maxAccomodation: "Anzahl der veröffentlichbaren Anzeigen %{value}",
    maxItem: "Anzahl der veröffentlichbaren Anzeigen %{value}",
    maxImagesPerCategory: "Gesamtanzahl der hochladbaren Bilder %{value}",
    maxPriceRules: "Anzahl der anwendbaren Preisregeln %{value}",
    durationDay: "Gültig für %{value} Tage, kostenlos verlängerbar",
    default: {
      title: "Basispaket",
      price: "Kostenlos",
    },
  },
  accomodationDefaultPackageName: "Basiswerbepaket",
  phone: "Telefon",
  language: "Sprache",
  appLanguage: "App-Sprache",
  en: "Englisch",
  de: "Deutsch",
  es: "Spanisch",
  fr: "Französisch",
  cz: "Tschechisch",
  hu: "Ungarisch",
  ru: "Russisch",
  settings: "Einstellungen",
  languages: "Gesprochene Sprachen",
  pricing: {
    regardlessGuestCount: "unabhängig von der Anzahl der Gäste",
    byNight: "/ Nacht",
    night: "Nacht",
    nightFrom: "ab Nacht",
    pricePerNight: "Preis pro Nacht",
    guest: "Gast",
    guestCountLabel1: "%{value} Gast",
    guestCountLabel2: "ab %{value} Gäste",
    nightCountLabel1: "%{value} Nacht",
    nightCountLabel2: "ab %{value} Nächten",
    nightCountLabel3: "ab %{value} Nächten",
    cleaningPrice: "Reinigungskosten",
  },
  apartAccomodation: {
    basePriceSettings: "Grundeinstellungen",
    pricingRulesDelete: "Löschen",
    pricingRules: "Preisregeln",
    volumeDiscountPricing: "Mengenrabatt",
    guestDiscountPricing: "Rabatt für Gäste",
    discount: "Rabatt",
    cleaningPrice: "Reinigungskosten",
    reservationLength: "Buchungsdauer",
    night: "Nacht",
    nightFrom: "Ab Nacht",
    pricePerNight: "Preis pro Nacht",
    guest: "Gast",
    currency: "Währung",
    pricing: "Preiseinstellungen",
    pricingTemplate: "Tägliche Preise",
    priceVisible: "Preise veröffentlicht",
    noBedroom: "Kein Schlafzimmer",
    noLivigingroom: "Kein Wohnzimmer",
    noBathroom: "Kein Badezimmer",
    created: "Erstellt",
    updated: "Aktualisiert",
    settings: "Einstellungen",
    finalization: "Finalisierung",
    enabled: "Veröffentlicht",
    updated: "Aktualisiert",
    deleted: "Gelöscht",
    nextToImages: "Weiter",
    add: "Neue Anzeige hinzufügen",
    modify: "Anzeige bearbeiten",
    address: "Adresse der Unterkunft",
    name: "Name der Unterkunft",
    description: "Beschreibung",
    accomodationType: "Art der Unterkunft",
    house: "Haus",
    apartment: "Apartment",
    room: "Zimmer",
    livingRoom: "Wohnzimmer",
    kitchen: "Ausgestattete Küche",
    wifi: "WLAN",
    hairDryer: "Haartrockner",
    tv: "TV",
    amenities: "Annehmlichkeiten",
    services: "Dienstleistungen",
    features: "Merkmale",
    pool: "Pool",
    freeParking: "Kostenloses Parken",
    gym: "Fitnessraum",
    location: "Lage",
    beachfront: "Strandnah",
    beachNearby: "In Strandnähe",
    restaurantsNearby: "In der Nähe von Restaurants",
    bustopNearby: "In der Nähe der Bushaltestelle",
    aiportNearby: "In der Nähe des Flughafens",
    shopsNearby: "In der Nähe von Geschäften",
    balcony: "Balkon",
    airConditioner: "Klimaanlage",
    seeview: "Meerblick",
    lakeside: "Am See",
    mountainview: "Bergblick",
    downtown: "Innenstadt",
    countryside: "Land",
    forestEdge: "Waldrand",
    golfCourseAdjacent: "Golfplatz",
    massage: "Massage",
    sauna: "Sauna",
    pool: "Pool",
    heatedPool: "Beheizter Pool",
    lift: "Aufzug",
    ground: "Erdgeschoss",
    barrierFree: "Barrierefrei",
    smartTv: "Smart TV",
    netflix: "Netflix",
    bedRoom: "Schlafzimmer",
    bed: "Bett",
    bedroom: "Schlafzimmer",
    bathroom: "Badezimmer",
    studio: "Studio",
    images: "Bilder",
    addImages: "Bilder hinzufügen",
    breakfastIncluded: "Frühstück inbegriffen",
    lunchIncluded: "Mittagessen inbegriffen",
    dinnerIncluded: "Abendessen inbegriffen",
    freeTransfer: "Kostenloser Flughafentransfer",
    babyBed: "Babybett",
    petsAllowed: "Haustiere erlaubt",
    welcomeDrink: "Willkommensgetränk",
    imageCategories: {
      rooms: "Zimmer",
      bathRoom: "Badezimmer",
      kitchen: "Küche",
      balkony: "Balkon",
      outdoor: "Außenbereich",
    },
  },
  validation: {
    required: "Pflichtfeld",
    selectionIsRequired: "Auswahl ist erforderlich",
  },
  typeMessage: "Nachricht...",
};
