export default {
  loginScreenEmailPassword: "Iniciar sesión",
  loginEmailPwButton: "Iniciar sesión con correo electrónico y contraseña",
  email: "Correo electrónico",
  loginButton: "Iniciar sesión",
  password: "Contraseña",
  createAccount: "Crear cuenta",
  forgotPassword: "Olvidé mi contraseña",
  resetPasswordButton: "Restablecer contraseña",
  resetPasswordScreen: "Olvidé mi contraseña",
  resetPasswordMessage: "Se ha enviado un enlace para restablecer la contraseña a su correo electrónico",
  providedEmailWasNotFound: "El correo electrónico proporcionado no fue encontrado",
  loginFailed: "Error al iniciar sesión",
  emailExists: "Esta cuenta ya existe",
  invalidEmailAddress: "Dirección de correo electrónico no válida",
  invalidPassword: "La contraseña debe tener al menos 6 caracteres",
  verificationSent: "Se ha enviado un correo electrónico de verificación. Por favor, revise su correo electrónico y confirme su registro.",
  accountInactive: "Su cuenta aún está inactiva",
  privacyPolicyButton: "Lea nuestra Política de privacidad",
  unknown: "Usuario desconocido",
  youMustBeLoggedIn: "Debe estar conectado para acceder a esta función.",
  youMustBeLoggedInToPost: "Debe estar conectado para publicar un anuncio.",
  
  aboutUs: 'apartseeker.com',
  seekDetailScreenTitle: "Detalles del Alojamiento",
  deleteTopicMessage: "¿Quieres eliminar esta conversación con todos sus mensajes?",
  chatScreenTitle: "Mensajes",
  seekListScreenTitle: "Explorar",
  seek: "Explorar",
  seekAndEnjoy: "",
  emailVisible: "Email Público",
  firstName: "Nombre",
  lastName: "Apellido",
  home: "Inicio",
  welcome: "Bienvenido",
  myAds: "Mis Anuncios",
  myAparts: "Apartamentos",
  myApartsShort: "Apto.",
  login: "Iniciar Sesión",
  tests: "Pruebas",
  examples: "Ejemplos",
  searchAddress: "Buscar Dirección",
  save: "Guardar",
  cancel: "Cancelar",
  back: "Atrás",
  delete: "Eliminar",
  forward: "Adelante",
  select: "Seleccionar",
  profileAdvSettings: "Configuración del Perfil del Anunciante",
  validationMinus: "El valor no puede ser negativo",
  validation0100: "El valor debe estar entre 0 y 100",
  validationPrice1: "El precio es obligatorio",
  validationPrice2: "El precio está mal ingresado",
  validationRequired: "Este campo es obligatorio",
  validationPrevValue: "Debe ser mayor que {{value}}",
  created: "Creado",
  updated: "Actualizado",
  loginFacebook: "Iniciar sesión con Facebook",
  profileNormalMenu: "Configuración",
  profileAdvMenu: "Para Anunciantes",
  profileScreenTitle: "Configuración",
  profileAdvScreenTitle: "Perfil del Anunciante",
  AdvertiserMenu: "Para Anunciantes",
  account: "Cuenta",
  profile: "Perfil",
  explore: "Explorar",
  
  iAmAdvertiser: "Soy Anunciante",
  approveTerms: "He leído y acepto los términos y condiciones",
  approveTermsDesc: "Tus anuncios no aparecerán hasta que aceptes los términos.",
  availableAccomodations: "Aún puedes publicar %{value} anuncios",
  noAvailableAccomodations: "No puedes publicar más anuncios",
  approveTermsIsRequired: "Para publicar un anuncio, acepta los términos y condiciones.",
  search: "Buscar",
  searchScreenTitle: "Buscar",

  searchByAddress: "Dirección",
  searchByMap: "Mapa",
  searchFavourites: "Favoritos",
  searchHistory: "Historial",
  logout: 'Cerrar Sesión',
  deleteSeeker: "Eliminar Mi Cuenta",
  areYouSureDeleteSeeker: "Tu cuenta y todos los datos asociados serán eliminados. ¿Estás seguro de que quieres continuar?", 
  areYouSureDeleteAccomodation: "Tu anuncio será eliminado. ¿Estás seguro de que quieres continuar?", 
  deleteAccomodation: "Eliminar Anuncio",
  pageNotFoundTitle: "404",
  pageNotFoundText: "Lo que estás buscando no está disponible actualmente, por favor vuelve más tarde.",
  emptyListText: "No hay elementos para mostrar",
  seekListEmptyText: "Aún no hay alojamientos en esta área.",
  seekListEmptyButtonText: "Si quieres anunciar, publica un anuncio aquí",
  chatEmptyText: "Aún no tienes mensajes. ¡Contacta con los anunciantes! Puedes hacerlo fácilmente usando el icono que se encuentra en los anuncios.",
  signupDesc:
    "Para publicar tu anuncio, debes aceptar los términos y condiciones.",
  advPackages: {
    myPackage: "Mi Paquete",
    general: "Actualmente solo está disponible el paquete básico de publicidad",
    maxAccomodation: "Número de anuncios que se pueden publicar %{value}",
    maxItem: "Número de anuncios que se pueden publicar %{value}",
    maxImagesPerCategory: "Número total de imágenes que se pueden subir %{value}",
    maxPriceRules: "Número de reglas de precios aplicables %{value}",
    durationDay: "Válido por %{value} días, se puede extender gratuitamente",
    default: {
      title: "Paquete Básico",
      price: "Gratis", 
    },
  },
  accomodationDefaultPackageName: "Paquete Básico de Publicidad",
  phone: "Teléfono",
  language: "Idioma",
  appLanguage: "Idioma de la Aplicación",
  en: "Inglés",
  de: "Alemán",
  es: "Español",
  fr: "Francés",
  cz: "Checo",
  hu: "Húngaro",
  ru: "Ruso",
  settings: "Configuración",
  languages: "Idiomas Hablados",
  pricing: {
    regardlessGuestCount: "independientemente del número de huéspedes",
    byNight: "/ noche",
    night: "noche",
    nightFrom: "desde noche",
    pricePerNight: "precio por noche",
    guest: "huésped",
    guestCountLabel1: "%{value} huésped",
    guestCountLabel2: "desde %{value} huéspedes",
    nightCountLabel1: "%{value} noche",
    nightCountLabel2: "desde %{value} noches",
    nightCountLabel3: "desde %{value} noches",
    cleaningPrice: "Costo de Limpieza",
  },
  apartAccomodation: {
    basePriceSettings: "Configuración Básica",
    pricingRulesDelete: "Eliminar",
    pricingRules: "Reglas de Precios",
    volumeDiscountPricing: "Descuento por Volumen",
    guestDiscountPricing: "Descuento por Huéspedes",
    discount: "Descuento",
    cleaningPrice: "Costo de Limpieza",
    reservationLength: "Duración de la Reserva",
    night: "Noche",
    nightFrom: "Desde Noche",
    pricePerNight: "Precio por Noche",
    guest: "Huésped",
    currency: "Moneda",
    pricing: "Precios",
    pricingTemplate: "Precios Diarios",
    priceVisible: "Precios Publicados",
    noBedroom: "Sin Dormitorio",
    noLivigingroom: "Sin Sala de Estar",
    noBathroom: "Sin Baño",
    created: "Creado",
    updated: "Actualizado",
    settings: "Configuración",
    finalization: "Finalización",
    enabled: "Publicado",
    updated: "Actualizado",
    deleted: "Eliminado",
    nextToImages: "Siguiente",
    add: "Agregar Nuevo Anuncio",
    modify: "Modificar Anuncio",
    address: "Dirección del Alojamiento",
    name: "Nombre del Alojamiento",
    description: "Descripción",
    accomodationType: "Tipo de Alojamiento",
    house: "Casa",
    apartment: "Apartamento",
    room: "Habitación",
    livingRoom: "Sala de Estar",
    kitchen: "Cocina Equipada",
    wifi: "Wifi",
    hairDryer: "Secador de Pelo",
    tv: "TV",
    amenities: "Comodidades",
    services: "Servicios",
    features: "Características",
    pool: "Piscina",
    freeParking: "Estacionamiento Gratis",
    gym: "Gimnasio",
    location: "Ubicación",
    beachfront: "Frente a la Playa",
    beachNearby: "Cerca de la Playa",
    restaurantsNearby: "Cerca de Restaurantes",
    bustopNearby: "Cerca de la Parada de Autobús",
    aiportNearby: "Cerca del Aeropuerto",
    shopsNearby: "Cerca de Tiendas",
    balcony: "Balcón",
    airConditioner: "Aire Acondicionado",
    seeview: "Vista al Mar",
    lakeside: "Frente al Lago",
    mountainview: "Vista a la Montaña",
    downtown: "Centro de la Ciudad",
    countryside: "Campo",
    forestEdge: "Borde del Bosque",
    golfCourseAdjacent: "Campo de Golf",
    massage: "Masaje",
    sauna: "Sauna",
    pool: "Piscina",
    heatedPool: "Piscina Climatizada",
    lift: "Ascensor",
    ground: "Planta Baja",
    barrierFree: "Sin Barreras",
    smartTv: "Smart TV",
    netflix: "Netflix",
    bedRoom: "Dormitorios",
    bed: "Cama",
    bedroom: "Dormitorio",
    bathroom: "Baño",
    studio: "Estudio",
    images: "Imágenes",
    addImages: "Agregar Imágenes",
    breakfastIncluded: "Desayuno Incluido",
    lunchIncluded: "Almuerzo Incluido",
    dinnerIncluded: "Cena Incluida",
    freeTransfer: "Transfer Aeropuerto Gratis",
    babyBed: "Cuna",
    petsAllowed: "Se Permiten Mascotas",
    welcomeDrink: "Bebida de Bienvenida",
    imageCategories: {
      rooms: "Habitaciones",
      bathRoom: "Baño",
      kitchen: "Cocina",
      balkony: "Balcón",
      outdoor: "Exterior",
    },
  },
  validation: {
    required: "Campo Obligatorio",
    selectionIsRequired: "La Selección es Obligatoria",
  },
  typeMessage: "Mensaje...",
};
