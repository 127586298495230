import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setLoading } = progressSlice.actions;

export { progressSlice };
