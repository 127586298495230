export default {
  loginScreenEmailPassword: "Accesso",
  loginEmailPwButton: "Accedi con email e password",
  email: "E-mail",
  loginButton: "Accedi",
  password: "Password",
  createAccount: "Crea un account",
  forgotPassword: "Password dimenticata",
  resetPasswordButton: "Reimposta password",
  resetPasswordScreen: "Password dimenticata",
  resetPasswordMessage: "Un link per reimpostare la password è stato inviato al tuo indirizzo email",
  providedEmailWasNotFound: "L'email fornita non è stata trovata",
  loginFailed: "Accesso fallito",
  emailExists: "Questo account esiste già",
  invalidEmailAddress: "Indirizzo email non valido",
  invalidPassword: "La password deve essere di almeno 6 caratteri",
  verificationSent: "Un'email di verifica è stata inviata. Controlla la tua email e conferma la tua registrazione.",
  accountInactive: "Il tuo account è ancora inattivo",
  privacyPolicyButton: "Leggi la nostra Informativa sulla privacy",
  unknown: "Utente sconosciuto",
  youMustBeLoggedIn: "Devi essere loggato per accedere a questa funzione.",
  youMustBeLoggedInToPost: "Devi essere loggato per pubblicare un annuncio.",
  
  aboutUs: 'apartseeker.com',
  seekDetailScreenTitle: "Dettagli Alloggio",
  deleteTopicMessage: "Vuoi eliminare questa conversazione con tutti i suoi messaggi?",
  chatScreenTitle: "Messaggi",
  seekListScreenTitle: "Esplora",
  seek: "Esplora",
  seekAndEnjoy: "",
  emailVisible: "Email Pubblica",
  firstName: "Nome",
  lastName: "Cognome",
  home: "Home",
  welcome: "Benvenuto",
  myAds: "I Miei Annunci",
  myAparts: "Appartamenti",
  myApartsShort: "Apt.",
  login: "Accedi",
  tests: "Test",
  examples: "Esempi",
  searchAddress: "Cerca Indirizzo",
  save: "Salva",
  cancel: "Annulla",
  back: "Indietro",
  delete: "Elimina",
  forward: "Avanti",
  select: "Seleziona",
  profileAdvSettings: "Impostazioni Profilo Inserzionista",
  validationMinus: "Il valore non può essere negativo",
  validation0100: "Il valore deve essere tra 0 e 100",
  validationPrice1: "Il prezzo è obbligatorio",
  validationPrice2: "Il prezzo è inserito in modo errato",
  validationRequired: "Questo campo è obbligatorio",
  validationPrevValue: "Deve essere maggiore di {{value}}",
  created: "Creato",
  updated: "Aggiornato",
  loginFacebook: "Accedi con Facebook",
  profileNormalMenu: "Impostazioni",
  profileAdvMenu: "Per Inserzionisti",
  profileScreenTitle: "Impostazioni",
  profileAdvScreenTitle: "Profilo Inserzionista",
  AdvertiserMenu: "Per Inserzionisti",
  account: "Account",
  profile: "Profilo",
  explore: "Esplora",
  
  iAmAdvertiser: "Sono un Inserzionista",
  approveTerms: "Ho letto e accetto i termini e le condizioni",
  approveTermsDesc: "I tuoi annunci non appariranno fino a quando non accetterai i termini.",
  availableAccomodations: "Puoi ancora pubblicare %{value} annunci",
  noAvailableAccomodations: "Non puoi pubblicare altri annunci",
  approveTermsIsRequired: "Per pubblicare un annuncio, accetta i termini e le condizioni.",
  search: "Cerca",
  searchScreenTitle: "Cerca",

  searchByAddress: "Indirizzo",
  searchByMap: "Mappa",
  searchFavourites: "Preferiti",
  searchHistory: "Storico",
  logout: 'Esci',
  deleteSeeker: "Elimina il Mio Account",
  areYouSureDeleteSeeker: "Il tuo account e tutti i dati associati saranno eliminati. Sei sicuro di voler continuare?", 
  areYouSureDeleteAccomodation: "Il tuo annuncio sarà eliminato. Sei sicuro di voler continuare?", 
  deleteAccomodation: "Elimina Annuncio",
  pageNotFoundTitle: "404",
  pageNotFoundText: "Quello che stai cercando non è attualmente disponibile, torna più tardi.",
  emptyListText: "Nessun elemento da mostrare",
  seekListEmptyText: "Non ci sono ancora alloggi in questa zona.",
  seekListEmptyButtonText: "Se vuoi fare pubblicità, pubblica un annuncio qui",
  chatEmptyText: "Non hai ancora messaggi. Contatta gli inserzionisti! Puoi farlo facilmente usando l'icona che si trova negli annunci.",
  signupDesc:
    "Per pubblicare il tuo annuncio, devi accettare i termini e le condizioni.",
  advPackages: {
    myPackage: "Il Mio Pacchetto",
    general: "Attualmente è disponibile solo il pacchetto base per inserzionisti",
    maxAccomodation: "Numero di annunci pubblicabili %{value}",
    maxItem: "Numero di annunci pubblicabili %{value}",
    maxImagesPerCategory: "Numero totale di immagini caricabili %{value}",
    maxPriceRules: "Numero di regole di prezzo applicabili %{value}",
    durationDay: "Valido per %{value} giorni, può essere esteso gratuitamente",
    default: {
      title: "Pacchetto Base",
      price: "Gratis", 
    },
  },
  accomodationDefaultPackageName: "Pacchetto Base per Inserzionisti",
  phone: "Telefono",
  language: "Lingua",
  appLanguage: "Lingua dell'App",
  en: "Inglese",
  de: "Tedesco",
  es: "Spagnolo",
  fr: "Francese",
  cz: "Ceco",
  hu: "Ungherese",
  ru: "Russo",
  settings: "Impostazioni",
  languages: "Lingue Parlate",
  pricing: {
    regardlessGuestCount: "indipendentemente dal numero di ospiti",
    byNight: "/ notte",
    night: "notte",
    nightFrom: "da notte",
    pricePerNight: "prezzo per notte",
    guest: "ospite",
    guestCountLabel1: "%{value} ospite",
    guestCountLabel2: "da %{value} ospiti",
    nightCountLabel1: "%{value} notte",
    nightCountLabel2: "da %{value} notti",
    nightCountLabel3: "da %{value} notti",
    cleaningPrice: "Costo di Pulizia",
  },
  apartAccomodation: {
    basePriceSettings: "Impostazioni di Base",
    pricingRulesDelete: "Elimina",
    pricingRules: "Regole di Prezzo",
    volumeDiscountPricing: "Sconto per Volume",
    guestDiscountPricing: "Sconto per Ospiti",
    discount: "Sconto",
    cleaningPrice: "Costo di Pulizia",
    reservationLength: "Durata della Prenotazione",
    night: "Notte",
    nightFrom: "Da Notte",
    pricePerNight: "Prezzo per Notte",
    guest: "Ospite",
    currency: "Valuta",
    pricing: "Impostazione Prezzi",
    pricingTemplate: "Prezzi Giornalieri",
    priceVisible: "Prezzi Pubblicati",
    noBedroom: "Nessuna Camera da Letto",
    noLivigingroom: "Nessun Soggiorno",
    noBathroom: "Nessun Bagno",
    created: "Creato",
    updated: "Aggiornato",
    settings: "Impostazioni",
    finalization: "Finalizzazione",
    enabled: "Pubblicato",
    updated: "Aggiornato",
    deleted: "Eliminato",
    nextToImages: "Avanti",
    add: "Aggiungi Nuovo Annuncio",
    modify: "Modifica Annuncio",
    address: "Indirizzo dell'Alloggio",
    name: "Nome dell'Alloggio",
    description: "Descrizione",
    accomodationType: "Tipo di Alloggio",
    house: "Casa",
    apartment: "Appartamento",
    room: "Stanza",
    livingRoom: "Soggiorno",
    kitchen: "Cucina Attrezzata",
    wifi: "Wifi",
    hairDryer: "Asciugacapelli",
    tv: "TV",
    amenities: "Servizi",
    services: "Servizi",
    features: "Caratteristiche",
    pool: "Piscina",
    freeParking: "Parcheggio Gratuito",
    gym: "Palestra",
    location: "Posizione",
    beachfront: "Fronte Mare",
    beachNearby: "Vicino alla Spiaggia",
    restaurantsNearby: "Vicino ai Ristoranti",
    bustopNearby: "Vicino alla Fermata dell'Autobus",
    aiportNearby: "Vicino all'Aeroporto",
    shopsNearby: "Vicino ai Negozi",
    balcony: "Balcone",
    airConditioner: "Aria Condizionata",
    seeview: "Vista Mare",
    lakeside: "Lungolago",
    mountainview: "Vista Montagna",
    downtown: "Centro Città",
    countryside: "Campagna",
    forestEdge: "Bordo Foresta",
    golfCourseAdjacent: "Campo da Golf",
    massage: "Massaggio",
    sauna: "Sauna",
    pool: "Piscina",
    heatedPool: "Piscina Riscaldata",
    lift: "Ascensore",
    ground: "Piano Terra",
    barrierFree: "Senza Barriere",
    smartTv: "Smart TV",
    netflix: "Netflix",
    bedRoom: "Camere da Letto",
    bed: "Letto",
    bedroom: "Camera da Letto",
    bathroom: "Bagno",
    studio: "Studio",
    images: "Immagini",
    addImages: "Aggiungi Immagini",
    breakfastIncluded: "Colazione Inclusa",
    lunchIncluded: "Pranzo Incluso",
    dinnerIncluded: "Cena Inclusa",
    freeTransfer: "Transfer Aeroportuale Gratuito",
    babyBed: "Lettino per Bambini",
    petsAllowed: "Animali Ammessi",
    welcomeDrink: "Drink di Benvenuto",
    imageCategories: {
      rooms: "Stanze",
      bathRoom: "Bagno",
      kitchen: "Cucina",
      balkony: "Balcone",
      outdoor: "Esterno",
    },
  },
  validation: {
    required: "Campo Obbligatorio",
    selectionIsRequired: "La Selezione è Obbligatoria",
  },
  typeMessage: "Messaggio...",
};
