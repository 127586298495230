
class DisplayFactory {
  constructor({ collection, item, i18Prefix, i18n }) {
    this._item = item;
    this._i18Prefix = i18Prefix ?? "apartAccomodation.";
    this._collection = collection;
    this._i18n = i18n;
  }
}

export default DisplayFactory;
