import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import hu from "~src/translation/lang-hu";
import en from "~src/translation/lang-en";
import es from "~src/translation/lang-es";
import fr from "~src/translation/lang-fr";
import de from "~src/translation/lang-de";
import sk from "~src/translation/lang-sk";
import cz from "~src/translation/lang-cz";
import it from "~src/translation/lang-it";
import ru from "~src/translation/lang-ru";

import { I18n } from "i18n-js";

class Translation {
  constructor(locale = "en") {
    const translations = {
      en: en,
      hu: hu,
      es: es,
      fr: fr,
      de: de,
      sk: sk,
      cz: cz,
      it: it,
      ru: ru
    };

    const i18n = new I18n(translations);
    i18n.defaultLocale = locale;
    i18n.locale = locale;
    i18n.missingBehavior = "guess";
    this._i18n = i18n;
  }

  i18n() {
    return this._i18n;
  }
}

const useLanguage = () => {
  const { mySeekerData } = useSelector((state) => state.seeker);
  const appLanguage = mySeekerData?.settings?.appLanguage;
  const [translationObj, setTranslationObj] = useState(new Translation());

  useEffect(() => {
    setTranslationObj(new Translation(appLanguage));
  }, [appLanguage]);

  return { i18n: translationObj.i18n() };
};

export { useLanguage };
