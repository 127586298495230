import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import accomodationCollection from "~src/collections/accomodationCollection";
// import DisplayFactoryAccomodation from "~src/components/factory/DisplayFactoryAccomodation";
import { useLanguage } from "~src/hooks/useLanguage";
import { fetchAccomodationDetailAction } from "~src/store/seek/fetchAccomodationDetailAction";
import { Image, View, Text } from "react-native";
// import { View, Text, TitleH1 } from "~src/components/styled-components";
import DisplayFactoryAccomodation from "~src/components/factory/DisplayFactoryAccomodation";
import { LoadingContainer, TitleH1 } from "~src/components/styled-components";

const SeekDetailScreen = () => {
  // rGe8DO4KOR6jRDq1F9mn
  const { i18n } = useLanguage();
  const { accomodationDetail } = useSelector((state) => state.seek);
  const { loading } = useSelector((state) => state.progress);
  const { accommodation_id } = useParams();
  const dispatch = useDispatch();
  const [factory, setFactory] = useState(null);

  useEffect(() => {
    setFactory(null);
    dispatch(fetchAccomodationDetailAction({ accomodationUid: accommodation_id }));
  }, [accommodation_id]);

  useEffect(() => {
    setFactory(
      new DisplayFactoryAccomodation({
        collection: accomodationCollection({ i18n }),
        item: accomodationDetail,
        i18n,
      })
    );
  }, [accomodationDetail]);

  if (loading) {
    return <LoadingContainer>Loading...</LoadingContainer>;
  }

  return (
    factory && (
      <View style={{}}>
        {/* <TitleH1>{accomodationDetail.basic.name}</TitleH1> */}
        <TitleH1>{factory.name()}</TitleH1>
        <Image
          source={{
            uri: factory.indexImageUrl(),
          }}
          style={[
            {
              aspectRatio: 16 / 9,
              width: 400,
            },
          ]}
        />

        {factory.address()}
        {/* {factory.indexImageUrl()} */}
        {/* {factory.indexImage()} */}
      </View>
    )
  );
};

export default SeekDetailScreen;
