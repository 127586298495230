export const ENDPOINT_COLLECTION_PATH = "__END__";

export const MAX_NIGHT_PRICE_RULE = 3;
export const MAX_GUEST_PRICE_RULE = 3;

export const IMAGE_CATS = 5;

export const SEEK_EVENT_INITIALIZE = "initialize";
export const SEEK_EVENT_MAP_PRESS = "map_press";
export const SEEK_EVENT_SCROLLER_CHANGE = "scroller_change";
export const SEEK_EVENT_SCROLL_TO_INDEX = "scroll_to_index";
export const SEEK_EVENT_SEARCH = "search";
export const SEEK_EVENT_DATA_LOADED = "loaded";
export const SEEK_EVENT_MARKER_CLICK = "marker_click";
export const SEEK_MOVE_TO_LOCATION = "move_to_location";
export const SEEK_SWITCH_TO_SMALL_MAP = "switch_to_small_map";
export const EVENT_LOGGED_IN = "logged_in";
export const EVENT_LOGGED_OUT = "logged_out";

export const ADV_PACKAGE_ACCOMODATION_DEFAULT = "RzZdh3vupl1zYub7UQVW";

export const ADV_STATE_PENDING = "pending";
export const ADV_STATE_APPROVED = "approved";
export const ADV_STATE_REFUSED = "refused";
