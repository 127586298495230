import { createSlice } from "@reduxjs/toolkit";
import { fetchSeekerByIdActionExtraReducer } from "./fetchSeekerByIdAction";
import { insertUpdateMySeekerExtraReducer } from "./insertUpdateMySeekerAction";
// import { deleteSeekerByIdActionExtraReducer } from "./deleteSeekerByIdAction";
// import { insertUpdateMySeekerAsyncExtraReducer } from "./insertUpdateMySeekerAsyncAction";

const initialState = {
  mySeekerData: null,
  currentSeeker: null,
};

const seekerSlice = createSlice({
  name: "seekerSlice",
  initialState,
  reducers: {
    resetSeeker: (state) => {
      state.mySeekerData = null;
      state.currentSeeker = null;
    },
  },
  extraReducers: (builder) => {
    // fetchSeekerByIdActionExtraReducer(builder);
    // insertUpdateMySeekerExtraReducer(builder);
    // deleteSeekerByIdActionExtraReducer(builder);
    // insertUpdateMySeekerAsyncExtraReducer(builder);
  },
});

export const { resetSeeker } = seekerSlice.actions;
export { seekerSlice };
