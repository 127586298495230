import { createAsyncThunk } from "@reduxjs/toolkit";
import { firebaseDB, firebaseApp } from "~src/config/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  startAfter,
  startAt,
  endAt,
} from "firebase/firestore";
import * as geofire from "geofire-common";
import { setLoading } from "../progress/progressSlice";
import uniq from "lodash/uniq";
import difference from "lodash/difference";
import { ADV_STATE_APPROVED } from "~src/constants";

export const fetchSeekListAction = createAsyncThunk(
  "seek/fetchSeekListAction",
  async ({ region, radius }, { dispatch, rejectWithValue, getState }) => {
    return null
    dispatch(setLoading(true));
    try {
      const center = [region.latitude, region.longitude];
      const bounds = geofire.geohashQueryBounds(center, radius);
      const { firstItemsCount, limit: lim } = getState().seek;
      const promises = [];
      for (const b of bounds) {
        const q = query(
          collection(firebaseDB, "accomodations"),
          orderBy("basic.address.location.geohash"),
          startAt(b[0]),
          endAt(b[1]),
          limit(lim)
        );
        promises.push(getDocs(q));
      }
      const snapshots = await Promise.all(promises);
      const matchingDocs = [];
      for (const snap of snapshots) {
        for (const doc of snap.docs) {
          const lat = doc.get("basic.address.location.lat");
          const lng = doc.get("basic.address.location.lng");
          const distanceInKm = geofire.distanceBetween([lat, lng], center);
          const distanceInM = distanceInKm * 1000;
          if (
            distanceInM <= radius &&
            doc.get("settings.enabled") &&
            doc.get("_.state") === ADV_STATE_APPROVED
          ) {
            const data = doc.data();
            matchingDocs.push({
              id: doc.id,
              ...data,
              distance: distanceInM * 1,
            });
          }
        }
      }

      let seekListData = matchingDocs.sort((a, b) => a.distance - b.distance);
      seekListData = seekListData.slice(0, firstItemsCount);

      const currentAdvertiserUids = seekListData?.map((item) => {
        return item._.userUid;
      });

      let snapshot = await getDocs(
        query(
          collection(firebaseDB, "seekers"),
          where(
            "__name__",
            "in",
            currentAdvertiserUids?.length ? uniq(currentAdvertiserUids) : ["-1"]
          )
        )
      );

      const advertiserData = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      //assign advertiser to accomodation
      seekListData?.forEach((item) => {
        item.advertiser = advertiserData.find(({ id }) => id === item._.userUid);
      });

      return seekListData;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const fetchSeekListActionExtraReducer = (builder) => {
  builder.addCase(fetchSeekListAction.fulfilled, (state, action) => {
    const seekListData = action.payload;
    const existingIds = state.seekData?.map((item) => item.id);
    const filteredItems = seekListData?.filter(
      (item) => !existingIds || !existingIds.includes(item.id)
    );
    state.seekData = existingIds?.length > 0 ? state.seekData.concat(filteredItems) : filteredItems;

    if (state.seekData?.length > limit) {
      // state.seekData.splice(0, limit);
    }
  });

  builder.addCase(fetchSeekListAction.rejected, (state, action) => {
    throw action.payload;
  });
};
