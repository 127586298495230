import { createAsyncThunk } from "@reduxjs/toolkit";
import { firebaseDB, firebaseApp } from "~src/config/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  startAfter,
  startAt,
  endAt,
} from "firebase/firestore";
import * as geofire from "geofire-common";
import { setLoading } from "../progress/progressSlice";

export const fetchSeekerByIdAction = createAsyncThunk(
  "seeker/fetchSeekerByIdAction",
  async ({ userUid }, { dispatch, rejectWithValue, getState }) => {
    dispatch(setLoading(true));
    try {
      return null;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const fetchSeekerByIdActionExtraReducer = (builder) => {
  builder.addCase(fetchSeekerByIdAction.fulfilled, (state, action) => {
    state.currentSeeker = action.payload;
  });

  builder.addCase(fetchSeekerByIdAction.rejected, (state, action) => {
    throw action.payload;
  });
};
