export default {
  loginScreenEmailPassword: "Přihlášení",
  loginEmailPwButton: "Přihlásit se pomocí e-mailu a hesla",
  email: "E-mail",
  loginButton: "Přihlásit se",
  password: "Heslo",
  createAccount: "Vytvořit účet",
  forgotPassword: "Zapomenuté heslo",
  resetPasswordButton: "Obnovit heslo",
  resetPasswordScreen: "Zapomenuté heslo",
  resetPasswordMessage: "Odkaz na obnovení hesla byl odeslán na váš e-mail",
  providedEmailWasNotFound: "Zadaný e-mail nebyl nalezen",
  loginFailed: "Přihlášení selhalo",
  emailExists: "Tento účet již existuje",
  invalidEmailAddress: "Neplatná e-mailová adresa",
  invalidPassword: "Heslo musí mít alespoň 6 znaků",
  verificationSent: "Ověřovací e-mail byl odeslán. Zkontrolujte prosím svůj e-mail a potvrďte registraci.",
  accountInactive: "Váš účet je stále neaktivní",
  privacyPolicyButton: "Přečtěte si naše Zásady ochrany osobních údajů",
  unknown: "Neznámý uživatel",
  youMustBeLoggedIn: "Pro přístup k této funkci musíte být přihlášeni.",
  youMustBeLoggedInToPost: "Pro vložení inzerátu se musíte přihlásit.",
  
  aboutUs: 'apartseeker.com',
  seekDetailScreenTitle: "Detaily Ubytování",
  deleteTopicMessage: "Chcete smazat tuto konverzaci se všemi zprávami?",
  chatScreenTitle: "Zprávy",
  seekListScreenTitle: "Prozkoumat",
  seek: "Prozkoumat",
  seekAndEnjoy: "",
  emailVisible: "E-mail Veřejný",
  firstName: "Křestní Jméno",
  lastName: "Příjmení",
  home: "Domov",
  welcome: "Vítejte",
  myAds: "Moje Inzeráty",
  myAparts: "Apartmány",
  myApartsShort: "Apt.",
  login: "Přihlásit se",
  tests: "Testy",
  examples: "Příklady",
  searchAddress: "Hledat Adresu",
  save: "Uložit",
  cancel: "Zrušit",
  back: "Zpět",
  delete: "Smazat",
  forward: "Vpřed",
  select: "Vybrat",
  profileAdvSettings: "Nastavení Profilu Inzerenta",
  validationMinus: "Hodnota nemůže být záporná",
  validation0100: "Hodnota musí být mezi 0 a 100",
  validationPrice1: "Cena je povinná",
  validationPrice2: "Cena je nesprávně zadána",
  validationRequired: "Toto pole je povinné",
  validationPrevValue: "Musí být větší než {{value}}",
  created: "Vytvořeno",
  updated: "Aktualizováno",
  loginFacebook: "Přihlásit se přes Facebook",
  profileNormalMenu: "Nastavení",
  profileAdvMenu: "Pro Inzerenty",
  profileScreenTitle: "Nastavení",
  profileAdvScreenTitle: "Profil Inzerenta",
  AdvertiserMenu: "Pro Inzerenty",
  account: "Účet",
  profile: "Profil",
  explore: "Prozkoumat",
  
  iAmAdvertiser: "Jsem Inzerent",
  approveTerms: "Přečetl jsem si a souhlasím s podmínkami",
  approveTermsDesc: "Vaše inzeráty nebudou zobrazeny, dokud nepřijmete podmínky.",
  availableAccomodations: "Stále můžete přidat %{value} inzerátů",
  noAvailableAccomodations: "Nemůžete přidat další inzeráty",
  approveTermsIsRequired: "Pro přidání inzerátu přijměte podmínky.",
  search: "Hledat",
  searchScreenTitle: "Hledat",

  searchByAddress: "Adresa",
  searchByMap: "Mapa",
  searchFavourites: "Oblíbené",
  searchHistory: "Historie",
  logout: 'Odhlásit se',
  deleteSeeker: "Smazat Můj Účet",
  areYouSureDeleteSeeker: "Váš účet a všechna související data budou smazána. Jste si jisti, že chcete pokračovat?", 
  areYouSureDeleteAccomodation: "Váš inzerát bude smazán. Jste si jisti, že chcete pokračovat?", 
  deleteAccomodation: "Smazat Inzerát",
  pageNotFoundTitle: "404",
  pageNotFoundText: "To, co hledáte, momentálně není k dispozici. Vraťte se později.",
  emptyListText: "Žádné položky k zobrazení",
  seekListEmptyText: "V této oblasti zatím nejsou žádné ubytování.",
  seekListEmptyButtonText: "Pokud chcete inzerovat, přidejte inzerát zde",
  chatEmptyText: "Zatím nemáte žádné zprávy. Kontaktujte inzerenty! Můžete to snadno udělat pomocí ikony v inzerátech.",
  signupDesc:
    "Pro zveřejnění svého inzerátu musíte přijmout podmínky.",
  advPackages: {
    myPackage: "Můj Balíček",
    general: "Momentálně je k dispozici pouze základní balíček pro inzerenty",
    maxAccomodation: "Počet inzerátů, které můžete přidat %{value}",
    maxItem: "Počet inzerátů, které můžete přidat %{value}",
    maxImagesPerCategory: "Celkový počet obrázků, které můžete nahrát %{value}",
    maxPriceRules: "Počet použitelných cenových pravidel %{value}",
    durationDay: "Platí %{value} dní, lze prodloužit zdarma",
    default: {
      title: "Základní Balíček",
      price: "Zdarma", 
    },
  },
  accomodationDefaultPackageName: "Základní Inzertní Balíček",
  phone: "Telefon",
  language: "Jazyk",
  appLanguage: "Jazyk Aplikace",
  en: "Anglicky",
  de: "Německy",
  es: "Španělsky",
  fr: "Francouzsky",
  cz: "Česky",
  hu: "Maďarsky",
  ru: "Rusky",
  settings: "Nastavení",
  languages: "Mluvené Jazyky",
  pricing: {
    regardlessGuestCount: "bez ohledu na počet hostů",
    byNight: "/ noc",
    night: "noc",
    nightFrom: "od noci",
    pricePerNight: "cena za noc",
    guest: "host",
    guestCountLabel1: "%{value} host",
    guestCountLabel2: "od %{value} hostů",
    nightCountLabel1: "%{value} noc",
    nightCountLabel2: "od %{value} nocí",
    nightCountLabel3: "od %{value} nocí",
    cleaningPrice: "Poplatek za Úklid",
  },
  apartAccomodation: {
    basePriceSettings: "Základní Nastavení",
    pricingRulesDelete: "Smazat",
    pricingRules: "Cenová Pravidla",
    volumeDiscountPricing: "Sleva za Objem",
    guestDiscountPricing: "Sleva pro Hosty",
    discount: "Sleva",
    cleaningPrice: "Poplatek za Úklid",
    reservationLength: "Délka Rezervace",
    night: "Noc",
    nightFrom: "Od Noci",
    pricePerNight: "Cena za Noc",
    guest: "Host",
    currency: "Měna",
    pricing: "Nastavení Cen",
    pricingTemplate: "Denní Ceny",
    priceVisible: "Ceny Publikovány",
    noBedroom: "Žádná Ložnice",
    noLivigingroom: "Žádný Obývací Pokoj",
    noBathroom: "Žádná Koupelna",
    created: "Vytvořeno",
    updated: "Aktualizováno",
    settings: "Nastavení",
    finalization: "Finalizace",
    enabled: "Publikováno",
    updated: "Aktualizováno",
    deleted: "Smazáno",
    nextToImages: "Další",
    add: "Přidat Nový Inzerát",
    modify: "Upravit Inzerát",
    address: "Adresa Ubytování",
    name: "Název Ubytování",
    description: "Popis",
    accomodationType: "Typ Ubytování",
    house: "Dům",
    apartment: "Apartmán",
    room: "Pokoj",
    livingRoom: "Obývací Pokoj",
    kitchen: "Vybavená Kuchyň",
    wifi: "Wifi",
    hairDryer: "Fén",
    tv: "TV",
    amenities: "Vybavení",
    services: "Služby",
    features: "Vlastnosti",
    pool: "Bazén",
    freeParking: "Parkování Zdarma",
    gym: "Posilovna",
    location: "Poloha",
    beachfront: "U Pláže",
    beachNearby: "Blízko Pláže",
    restaurantsNearby: "Blízko Restaurací",
    bustopNearby: "Blízko Autobusové Zastávky",
    aiportNearby: "Blízko Letiště",
    shopsNearby: "Blízko Obchodů",
    balcony: "Balkón",
    airConditioner: "Klimatizace",
    seeview: "Výhled na Moře",
    lakeside: "U Jezera",
    mountainview: "Výhled na Hory",
    downtown: "Centrum Města",
    countryside: "Venkov",
    forestEdge: "Okraj Lesa",
    golfCourseAdjacent: "Golfové Hřiště",
    massage: "Masáž",
    sauna: "Sauna",
    pool: "Bazén",
    heatedPool: "Vyhřívaný Bazén",
    lift: "Výtah",
    ground: "Přízemí",
    barrierFree: "Bez Bariér",
    smartTv: "Smart TV",
    netflix: "Netflix",
    bedRoom: "Ložnice",
    bed: "Postel",
    bedroom: "Ložnice",
    bathroom: "Koupelna",
    studio: "Studio",
    images: "Obrázky",
    addImages: "Přidat Obrázky",
    breakfastIncluded: "Snídaně v Ceně",
    lunchIncluded: "Oběd v Ceně",
    dinnerIncluded: "Večeře v Ceně",
    freeTransfer: "Bezplatný Transfer z Letiště",
    babyBed: "Dětská Postýlka",
    petsAllowed: "Domácí Mazlíčci Povolení",
    welcomeDrink: "Uvítací Nápoj",
    imageCategories: {
      rooms: "Pokoje",
      bathRoom: "Koupelna",
      kitchen: "Kuchyň",
      balkony: "Balkón",
      outdoor: "Exteriér",
    },
  },
  validation: {
    required: "Povinné Pole",
    selectionIsRequired: "Výběr je Povinný",
  },
  typeMessage: "Zpráva...",
};
