export default {
  loginScreenEmailPassword: "Login",
  loginEmailPwButton: "Login with email and password",
  email: "E-mail",
  loginButton: "Login",
  password: "Password",
  createAccount: "Create account",
  forgotPassword: "Forgot password",
  resetPasswordButton: "Reset password",
  resetPasswordScreen: "Forgot password",
  resetPasswordMessage: "A password reset link has been sent to your email",
  providedEmailWasNotFound: "The provided email was not found",
  loginFailed: "Login failed",
  emailExists: "This account already exists",
  invalidEmailAddress: "Invalid email address",
  invalidPassword: "Password must be at least 6 characters",
  verificationSent:
    "Verification email has been sent. Please check your email and confirm your registration.",
  accountInactive: "Your account is still inactive",
  privacyPolicyButton: "Read our Privacy Policy",
  unknown: "Unknown user",
  youMustBeLoggedIn: "You must be logged in to access this feature.",
  youMustBeLoggedInToPost: "You must be logged in to post an advertisement.",
  
  aboutUs: "apartseeker.com",
  seekDetailScreenTitle: "Accommodation Details",
  deleteTopicMessage: "Do you want to delete this conversation with all its messages?",
  chatScreenTitle: "Messages",
  seekListScreenTitle: "Explore",
  seek: "Explore",
  seekAndEnjoy: "",
  emailVisible: "Email Public",
  firstName: "First Name",
  lastName: "Last Name",
  home: "Home",
  welcome: "Welcome",
  myAds: "My Ads",
  myAparts: "Apartments",
  myApartsShort: "Apt.",
  login: "Login",
  tests: "Tests",
  examples: "Examples",
  searchAddress: "Search Address",
  save: "Save",
  cancel: "Cancel",
  back: "Back",
  delete: "Delete",
  forward: "Forward",
  select: "Select",
  profileAdvSettings: "Advertiser Profile Settings",
  validationMinus: "The value cannot be negative",
  validation0100: "The value must be between 0 and 100",
  validationPrice1: "Price is required",
  validationPrice2: "The price is incorrectly entered",
  validationRequired: "This field is required",
  validationPrevValue: "Must be greater than {{value}}",
  created: "Created",
  updated: "Updated",
  loginFacebook: "Login with Facebook",
  profileNormalMenu: "Settings",
  profileAdvMenu: "For Advertisers",
  profileScreenTitle: "Settings",
  profileAdvScreenTitle: "Advertiser Profile",
  AdvertiserMenu: "For Advertisers",
  account: "Account",
  profile: "Profile",
  explore: "Explore",

  iAmAdvertiser: "I am an Advertiser",
  approveTerms: "I have read and accept the terms and conditions",
  approveTermsDesc: "Your ads will not appear until you accept the terms.",
  availableAccomodations: "You can still post %{value} ads",
  noAvailableAccomodations: "You cannot post any more ads",
  approveTermsIsRequired: "To post an ad, accept the terms and conditions.",
  search: "Search",
  searchScreenTitle: "Search",

  searchByAddress: "Address",
  searchByMap: "Map",
  searchFavourites: "Favorites",
  searchHistory: "History",
  logout: "Logout",
  deleteSeeker: "Delete My Account",
  areYouSureDeleteSeeker:
    "Your account and all associated data will be deleted. Are you sure you want to proceed?",
  areYouSureDeleteAccomodation: "Your ad will be deleted. Are you sure you want to proceed?",
  deleteAccomodation: "Delete Ad",
  pageNotFoundTitle: "404",
  pageNotFoundText: "What you're looking for is currently unavailable, please check back later.",
  emptyListText: "No items to display",
  seekListEmptyText: "There are no accommodations in this area yet.",
  seekListEmptyButtonText: "If you want to advertise, post an ad here",
  chatEmptyText:
    "You have no messages yet. Contact the advertisers! You can easily do this using the icon found in the ads.",
  signupDesc: "To publish your ad, you need to accept the terms and conditions.",
  advPackages: {
    myPackage: "My Package",
    general: "Currently only the basic advertising package is available",
    maxAccomodation: "Number of ads that can be posted %{value}",
    maxItem: "Number of ads that can be posted %{value}",
    maxImagesPerCategory: "Total number of images that can be uploaded %{value}",
    maxPriceRules: "Number of applicable pricing rules %{value}",
    durationDay: "Valid for %{value} days, can be extended for free",
    default: {
      title: "Basic Package",
      price: "Free",
    },
  },
  accomodationDefaultPackageName: "Basic Advertising Package",
  phone: "Phone",
  language: "Language",
  appLanguage: "App Language",
  en: "English",
  de: "German",
  es: "Spanish",
  fr: "French",
  cz: "Czech",
  hu: "Hungarian",
  ru: "Russian",
  settings: "Settings",
  languages: "Spoken Languages",
  pricing: {
    regardlessGuestCount: "regardless of the number of guests",
    byNight: "/ night",
    night: "night",
    nightFrom: "from night",
    pricePerNight: "price per night",
    guest: "guest",
    guestCountLabel1: "%{value} guest",
    guestCountLabel2: "from %{value} guests",
    nightCountLabel1: "%{value} night",
    nightCountLabel2: "from %{value} nights",
    nightCountLabel3: "from %{value} nights",
    cleaningPrice: "Cleaning Fee",
  },
  apartAccomodation: {
    basePriceSettings: "Basic Settings",
    pricingRulesDelete: "Delete",
    pricingRules: "Pricing Rules",
    volumeDiscountPricing: "Volume Discount",
    guestDiscountPricing: "Guest Discount",
    discount: "Discount",
    cleaningPrice: "Cleaning Fee",
    reservationLength: "Length of Stay",
    night: "Night",
    nightFrom: "From Night",
    pricePerNight: "Price Per Night",
    guest: "Guest",
    currency: "Currency",
    pricing: "Pricing",
    pricingTemplate: "Daily Prices",
    priceVisible: "Prices Published",
    noBedroom: "No Bedroom",
    noLivigingroom: "No Living Room",
    noBathroom: "No Bathroom",
    created: "Created",
    updated: "Updated",
    settings: "Settings",
    finalization: "Finalization",
    enabled: "Published",
    updated: "Updated",
    deleted: "Deleted",
    nextToImages: "Next",
    add: "Add New Ad",
    modify: "Modify Ad",
    address: "Accommodation Address",
    name: "Accommodation Name",
    description: "Description",
    accomodationType: "Accommodation Type",
    house: "House",
    apartment: "Apartment",
    room: "Room",
    livingRoom: "Living Room",
    kitchen: "Equipped Kitchen",
    wifi: "Wifi",
    hairDryer: "Hair Dryer",
    tv: "TV",
    amenities: "Amenities",
    services: "Services",
    features: "Features",
    pool: "Pool",
    freeParking: "Free Parking",
    gym: "Gym",
    location: "Location",
    beachfront: "Beachfront",
    beachNearby: "Near the Beach",
    restaurantsNearby: "Near Restaurants",
    bustopNearby: "Near Bus Stop",
    aiportNearby: "Near Airport",
    shopsNearby: "Near Shops",
    balcony: "Balcony",
    airConditioner: "Air Conditioner",
    seeview: "Sea View",
    lakeside: "Lakeside",
    mountainview: "Mountain View",
    downtown: "Downtown",
    countryside: "Countryside",
    forestEdge: "Forest Edge",
    golfCourseAdjacent: "Golf Course",
    massage: "Massage",
    sauna: "Sauna",
    pool: "Pool",
    heatedPool: "Heated Pool",
    lift: "Lift",
    ground: "Ground Floor",
    barrierFree: "Barrier-Free",
    smartTv: "Smart TV",
    netflix: "Netflix",
    bedRoom: "Bedrooms",
    bed: "Bed",
    bedroom: "Bedroom",
    bathroom: "Bathroom",
    studio: "Studio",
    images: "Images",
    addImages: "Add Images",
    breakfastIncluded: "Breakfast Included",
    lunchIncluded: "Lunch Included",
    dinnerIncluded: "Dinner Included",
    freeTransfer: "Free Airport Transfer",
    babyBed: "Baby Bed",
    petsAllowed: "Pet Friendly",
    welcomeDrink: "Welcome Drink",
    imageCategories: {
      rooms: "Rooms",
      bathRoom: "Bathroom",
      kitchen: "Kitchen",
      balkony: "Balcony",
      outdoor: "Outdoor",
    },
  },
  validation: {
    required: "Required Field",
    selectionIsRequired: "Selection is Required",
  },
  typeMessage: "Message...",
};
