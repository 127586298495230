// import { StyleSheet } from "react";

import { theme as myColors } from "~src/styles/theme";

const DividerLine = ({ style }) => {
  return null;
  return <div style={{}} />;
};

// const styles = StyleSheet.create({
//   line: {
//     height: 1,
//     backgroundColor: myColors.dividerLine,
//     marginVertical: 10,
//   },
// });

export default DividerLine;
