export default {
  loginScreenEmailPassword: "Connexion",
  loginEmailPwButton: "Se connecter avec e-mail et mot de passe",
  email: "E-mail",
  loginButton: "Se connecter",
  password: "Mot de passe",
  createAccount: "Créer un compte",
  forgotPassword: "Mot de passe oublié",
  resetPasswordButton: "Réinitialiser le mot de passe",
  resetPasswordScreen: "Mot de passe oublié",
  resetPasswordMessage: "Un lien de réinitialisation de mot de passe a été envoyé à votre e-mail",
  providedEmailWasNotFound: "L'e-mail fourni n'a pas été trouvé",
  loginFailed: "Échec de la connexion",
  emailExists: "Ce compte existe déjà",
  invalidEmailAddress: "Adresse e-mail invalide",
  invalidPassword: "Le mot de passe doit comporter au moins 6 caractères",
  verificationSent: "Un e-mail de vérification a été envoyé. Veuillez vérifier votre e-mail et confirmer votre inscription.",
  accountInactive: "Votre compte est encore inactif",
  privacyPolicyButton: "Lisez notre politique de confidentialité",
  unknown: "Utilisateur inconnu",
  youMustBeLoggedIn: "Vous devez être connecté pour accéder à cette fonctionnalité.",
  youMustBeLoggedInToPost: "Vous devez être connecté pour publier une annonce.",
  
  aboutUs: "apartseeker.com",
  seekDetailScreenTitle: "Détails de l'Hébergement",
  deleteTopicMessage: "Voulez-vous supprimer cette conversation avec tous ses messages?",
  chatScreenTitle: "Messages",
  seekListScreenTitle: "Explorer",
  seek: "Explorer",
  seekAndEnjoy: "",
  emailVisible: "Email Public",
  firstName: "Prénom",
  lastName: "Nom de Famille",
  home: "Accueil",
  welcome: "Bienvenue",
  myAds: "Mes Annonces",
  myAparts: "Appartements",
  myApartsShort: "Apt.",
  login: "Connexion",
  tests: "Tests",
  examples: "Exemples",
  searchAddress: "Rechercher Adresse",
  save: "Enregistrer",
  cancel: "Annuler",
  back: "Retour",
  delete: "Supprimer",
  forward: "Avancer",
  select: "Sélectionner",
  profileAdvSettings: "Paramètres du Profil Annonceur",
  validationMinus: "La valeur ne peut pas être négative",
  validation0100: "La valeur doit être comprise entre 0 et 100",
  validationPrice1: "Le prix est obligatoire",
  validationPrice2: "Le prix est incorrectement saisi",
  validationRequired: "Ce champ est obligatoire",
  validationPrevValue: "Doit être supérieur à {{value}}",
  created: "Créé",
  updated: "Mis à Jour",
  loginFacebook: "Connexion avec Facebook",
  profileNormalMenu: "Paramètres",
  profileAdvMenu: "Pour les Annonceurs",
  profileScreenTitle: "Paramètres",
  profileAdvScreenTitle: "Profil Annonceur",
  AdvertiserMenu: "Pour les Annonceurs",
  account: "Compte",
  profile: "Profil",
  explore: "Explorer",

  iAmAdvertiser: "Je suis Annonceur",
  approveTerms: "J'ai lu et j'accepte les termes et conditions",
  approveTermsDesc: "Vos annonces n'apparaîtront pas tant que vous n'aurez pas accepté les termes.",
  availableAccomodations: "Vous pouvez encore publier %{value} annonces",
  noAvailableAccomodations: "Vous ne pouvez plus publier d'annonces",
  approveTermsIsRequired: "Pour publier une annonce, acceptez les termes et conditions.",
  search: "Rechercher",
  searchScreenTitle: "Rechercher",

  searchByAddress: "Adresse",
  searchByMap: "Carte",
  searchFavourites: "Favoris",
  searchHistory: "Historique",
  logout: "Déconnexion",
  deleteSeeker: "Supprimer Mon Compte",
  areYouSureDeleteSeeker:
    "Votre compte et toutes les données associées seront supprimés. Êtes-vous sûr de vouloir continuer?",
  areYouSureDeleteAccomodation: "Votre annonce sera supprimée. Êtes-vous sûr de vouloir continuer?",
  deleteAccomodation: "Supprimer l'Annonce",
  pageNotFoundTitle: "404",
  pageNotFoundText:
    "Ce que vous cherchez est actuellement indisponible, veuillez revenir plus tard.",
  emptyListText: "Aucun élément à afficher",
  seekListEmptyText: "Il n'y a pas encore d'hébergements dans cette zone.",
  seekListEmptyButtonText: "Si vous voulez annoncer, publiez une annonce ici",
  chatEmptyText:
    "Vous n'avez pas encore de messages. Contactez les annonceurs! Vous pouvez facilement le faire en utilisant l'icône trouvée dans les annonces.",
  signupDesc: "Pour publier votre annonce, vous devez accepter les termes et conditions.",
  advPackages: {
    myPackage: "Mon Forfait",
    general: "Actuellement, seul le forfait de base pour annonceurs est disponible",
    maxAccomodation: "Nombre d'annonces pouvant être publiées %{value}",
    maxItem: "Nombre d'annonces pouvant être publiées %{value}",
    maxImagesPerCategory: "Nombre total d'images pouvant être téléchargées %{value}",
    maxPriceRules: "Nombre de règles de tarification applicables %{value}",
    durationDay: "Valable %{value} jours, peut être prolongé gratuitement",
    default: {
      title: "Forfait de Base",
      price: "Gratuit",
    },
  },
  accomodationDefaultPackageName: "Forfait de Base pour Annonceurs",
  phone: "Téléphone",
  language: "Langue",
  appLanguage: "Langue de l'Application",
  en: "Anglais",
  de: "Allemand",
  es: "Espagnol",
  fr: "Français",
  cz: "Tchèque",
  hu: "Hongrois",
  ru: "Russe",
  settings: "Paramètres",
  languages: "Langues Parlées",
  pricing: {
    regardlessGuestCount: "indépendamment du nombre de personnes",
    byNight: "/ nuit",
    night: "nuit",
    nightFrom: "à partir de nuit",
    pricePerNight: "prix par nuit",
    guest: "invité",
    guestCountLabel1: "%{value} invité",
    guestCountLabel2: "à partir de %{value} invités",
    nightCountLabel1: "%{value} nuit",
    nightCountLabel2: "à partir de %{value} nuits",
    nightCountLabel3: "à partir de %{value} nuits",
    cleaningPrice: "Frais de Nettoyage",
  },
  apartAccomodation: {
    basePriceSettings: "Paramètres de Base",
    pricingRulesDelete: "Supprimer",
    pricingRules: "Règles de Tarification",
    volumeDiscountPricing: "Remise de Volume",
    guestDiscountPricing: "Remise pour Invités",
    discount: "Remise",
    cleaningPrice: "Frais de Nettoyage",
    reservationLength: "Durée de la Réservation",
    night: "Nuit",
    nightFrom: "À partir de Nuit",
    pricePerNight: "Prix par Nuit",
    guest: "Invité",
    currency: "Monnaie",
    pricing: "Tarifs",
    pricingTemplate: "Tarifs Journaliers",
    priceVisible: "Prix Publiés",
    noBedroom: "Pas de Chambre",
    noLivigingroom: "Pas de Salon",
    noBathroom: "Pas de Salle de Bain",
    created: "Créé",
    updated: "Mis à Jour",
    settings: "Paramètres",
    finalization: "Finalisation",
    enabled: "Publié",
    updated: "Mis à Jour",
    deleted: "Supprimé",
    nextToImages: "Suivant",
    add: "Ajouter une Nouvelle Annonce",
    modify: "Modifier l'Annonce",
    address: "Adresse de l'Hébergement",
    name: "Nom de l'Hébergement",
    description: "Description",
    accomodationType: "Type d'Hébergement",
    house: "Maison",
    apartment: "Appartement",
    room: "Chambre",
    livingRoom: "Salon",
    kitchen: "Cuisine Équipée",
    wifi: "Wifi",
    hairDryer: "Sèche-Cheveux",
    tv: "TV",
    amenities: "Commodités",
    services: "Services",
    features: "Caractéristiques",
    pool: "Piscine",
    freeParking: "Parking Gratuit",
    gym: "Salle de Sport",
    location: "Emplacement",
    beachfront: "Front de Mer",
    beachNearby: "Près de la Plage",
    restaurantsNearby: "Près des Restaurants",
    bustopNearby: "Près de l'Arrêt de Bus",
    aiportNearby: "Près de l'Aéroport",
    shopsNearby: "Près des Magasins",
    balcony: "Balcon",
    airConditioner: "Climatisation",
    seeview: "Vue sur Mer",
    lakeside: "Au Bord du Lac",
    mountainview: "Vue sur la Montagne",
    downtown: "Centre-Ville",
    countryside: "Campagne",
    forestEdge: "Bord de Forêt",
    golfCourseAdjacent: "Terrain de Golf",
    massage: "Massage",
    sauna: "Sauna",
    pool: "Piscine",
    heatedPool: "Piscine Chauffée",
    lift: "Ascenseur",
    ground: "Rez-de-Chaussée",
    barrierFree: "Sans Barrières",
    smartTv: "Smart TV",
    netflix: "Netflix",
    bedRoom: "Chambres",
    bed: "Lit",
    bedroom: "Chambre",
    bathroom: "Salle de Bain",
    studio: "Studio",
    images: "Images",
    addImages: "Ajouter des Images",
    breakfastIncluded: "Petit Déjeuner Inclus",
    lunchIncluded: "Déjeuner Inclus",
    dinnerIncluded: "Dîner Inclus",
    freeTransfer: "Transfert Gratuit de l'Aéroport",
    babyBed: "Lit Bébé",
    petsAllowed: "Animaux Autorisés",
    welcomeDrink: "Boisson de Bienvenue",
    imageCategories: {
      rooms: "Chambres",
      bathRoom: "Salle de Bain",
      kitchen: "Cuisine",
      balkony: "Balcon",
      outdoor: "Extérieur",
    },
  },
  validation: {
    required: "Champ Obligatoire",
    selectionIsRequired: "La Sélection est Obligatoire",
  },
  typeMessage: "Message...",
};
