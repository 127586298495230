export const theme = {
  tabBarColor: "#daeff1",
  tabBarColorsAdv: {
    tabBarColor: "#fdf6d3" /* '#e7fbda'*/,
  },
  facebookButtonBackground: "#4267B2",
  normalButtonBackground: "rgba(255, 255, 255, 1)",
  backgroundOpacity: "rgba(255, 255, 255, .9)",
  panelBackground: "#f0eff0",
  detailContainerBackground: "#daeff1",
  detailsBackground: "#ffffff",
  // detailsBackground: "#fefff5",

  primaryContainer: "#febff9",
  secondaryContainer: "#bad2d9",
  // secondaryContainer: "#000000",
  titleColor: "#fb2de3",
  information: "#f4b400",
  primary: "#0a6dc9",
  dividerLine: "#f5bcfb",
  menuContainer: "#f5bcfb",
  sumbitButton: "#fb2de3",
  cancelButton: "#363636",
  success: "#68d846",
  trashColor: "#fb2de3",
  trashColor2: "#ff0000",
  secondary: "rgba(98, 91, 113, 1)",
  tertiary: "rgba(125, 82, 96, 1)",
  tertiaryContainer: "rgba(255, 216, 228, 1)",
  surface: null,
  surfaceVariant: "#ffffff",
  surfaceDisabled: "rgba(28, 27, 31, 0.12)",
  background: "#ffffff",
  error: "rgba(179, 38, 30, 1)",
  errorContainer: "#88ffcc",
  onPrimary: "rgba(255, 255, 255, 1)",
  onPrimaryContainer: "rgba(33, 0, 93, 1)",
  onSecondary: "rgba(255, 255, 255, 1)",
  onSecondaryContainer: "rgba(29, 25, 43, 1)",
  onTertiary: "rgba(255, 255, 255, 1)",
  onTertiaryContainer: "rgba(49, 17, 29, 1)",
  onSurface: "rgba(28, 27, 31, 1)",
  onSurfaceVariant: "rgba(73, 69, 79, 1)",
  onSurfaceDisabled: "rgba(28, 27, 31, 0.38)",
  onError: "rgba(255, 255, 255, 1)",
  onErrorContainer: "rgba(65, 14, 11, 1)",
  onBackground: "rgba(28, 27, 31, 1)",
  outline: "rgba(121, 116, 126, 1)",
  outlineVariant: "rgba(202, 196, 208, 1)",
  inverseSurface: "rgba(49, 48, 51, 1)",
  inverseOnSurface: "rgba(244, 239, 244, 1)",
  inversePrimary: "rgba(208, 188, 255, 1)",
  shadow: "rgba(0, 0, 0, 1)",
  scrim: "rgba(0, 0, 0, 1)",
  backdrop: "rgba(50, 47, 55, 0.4)",
  elevation: {
    level0: "transparent",
    level1: "rgb(247, 243, 249)",
    level2: "rgb(243, 237, 246)",
    level3: "rgb(238, 232, 244)",
    level4: "rgb(236, 230, 243)",
    level5: "rgb(233, 227, 241)",
  },
};
