export default {
  loginScreenEmailPassword: "Login",
  loginEmailPwButton: "Login with email and password",
  email: "E-mail",
  loginButton: "Login",
  password: "Password",
  createAccount: "Create account",
  forgotPassword: "Forgot password",
  resetPasswordButton: "Reset password",
  resetPasswordScreen: "Forgot password",
  resetPasswordMessage: "A password reset link has been sent to your email",
  providedEmailWasNotFound: "The provided email was not found",
  loginFailed: "Login failed",
  emailExists: "This account already exists",
  invalidEmailAddress: "Invalid email address",
  invalidPassword: "Password must be at least 6 characters",
  verificationSent:
    "Verification email has been sent. Please check your email and confirm your registration.",
  accountInactive: "Your account is still inactive",

  aboutUs: 'apartseeker.com',
  seekDetailScreenTitle: "Detaily Ubytovania",
  deleteTopicMessage: "Chcete odstrániť túto konverzáciu so všetkými správami?",
  chatScreenTitle: "Správy",
  seekListScreenTitle: "Preskúmať",
  seek: "Preskúmať",
  seekAndEnjoy: "",
  emailVisible: "E-mail Verejný",
  firstName: "Krstné Meno",
  lastName: "Priezvisko",
  home: "Domov",
  welcome: "Vitajte",
  myAds: "Moje Inzeráty",
  myAparts: "Apartmány",
  myApartsShort: "Apt.",
  login: "Prihlásiť sa",
  tests: "Testy",
  examples: "Príklady",
  searchAddress: "Hľadať Adresu",
  save: "Uložiť",
  cancel: "Zrušiť",
  back: "Späť",
  delete: "Odstrániť",
  forward: "Vpred",
  select: "Vybrať",
  profileAdvSettings: "Nastavenia Profilu Inzerenta",
  validationMinus: "Hodnota nemôže byť negatívna",
  validation0100: "Hodnota musí byť medzi 0 a 100",
  validationPrice1: "Cena je povinná",
  validationPrice2: "Cena je nesprávne zadaná",
  validationRequired: "Toto pole je povinné",
  validationPrevValue: "Musí byť väčšie ako {{value}}",
  created: "Vytvorené",
  updated: "Aktualizované",
  loginFacebook: "Prihlásiť sa cez Facebook",
  profileNormalMenu: "Nastavenia",
  profileAdvMenu: "Pre Inzerentov",
  profileScreenTitle: "Nastavenia",
  profileAdvScreenTitle: "Profil Inzerenta",
  AdvertiserMenu: "Pre Inzerentov",
  account: "Účet",
  profile: "Profil",
  explore: "Preskúmať",
  
  iAmAdvertiser: "Som Inzerent",
  approveTerms: "Prečítal som si a súhlasím s podmienkami",
  approveTermsDesc: "Vaše inzeráty sa nebudú zobrazovať, kým neprijmete podmienky.",
  availableAccomodations: "Môžete ešte pridať %{value} inzerátov",
  noAvailableAccomodations: "Nemôžete pridať viac inzerátov",
  approveTermsIsRequired: "Ak chcete pridať inzerát, prijmite podmienky.",
  search: "Hľadať",
  searchScreenTitle: "Hľadať",

  searchByAddress: "Adresa",
  searchByMap: "Mapa",
  searchFavourites: "Obľúbené",
  searchHistory: "História",
  logout: 'Odhlásiť sa',
  deleteSeeker: "Odstrániť Môj Účet",
  areYouSureDeleteSeeker: "Váš účet a všetky súvisiace údaje budú odstránené. Ste si istí, že chcete pokračovať?", 
  areYouSureDeleteAccomodation: "Váš inzerát bude odstránený. Ste si istí, že chcete pokračovať?", 
  deleteAccomodation: "Odstrániť Inzerát",
  pageNotFoundTitle: "404",
  pageNotFoundText: "To, čo hľadáte, momentálne nie je k dispozícii. Vráťte sa neskôr.",
  emptyListText: "Žiadne položky na zobrazenie",
  seekListEmptyText: "V tejto oblasti ešte nie sú žiadne ubytovania.",
  seekListEmptyButtonText: "Ak chcete inzerovať, pridajte inzerát tu",
  chatEmptyText: "Zatiaľ nemáte žiadne správy. Kontaktujte inzerentov! Môžete to ľahko urobiť pomocou ikony v inzerátoch.",
  signupDesc:
    "Ak chcete zverejniť svoj inzerát, musíte prijať podmienky.",
  advPackages: {
    myPackage: "Môj Balíček",
    general: "Momentálne je k dispozícii iba základný balík pre inzerentov",
    maxAccomodation: "Počet inzerátov, ktoré môžete pridať %{value}",
    maxItem: "Počet inzerátov, ktoré môžete pridať %{value}",
    maxImagesPerCategory: "Celkový počet obrázkov, ktoré môžete nahrať %{value}",
    maxPriceRules: "Počet uplatniteľných cenových pravidiel %{value}",
    durationDay: "Platí %{value} dní, možno predĺžiť zdarma",
    default: {
      title: "Základný Balíček",
      price: "Zdarma", 
    },
  },
  accomodationDefaultPackageName: "Základný Inzertný Balíček",
  phone: "Telefón",
  language: "Jazyk",
  appLanguage: "Jazyk Aplikácie",
  en: "Anglicky",
  de: "Nemecky",
  es: "Španielsky",
  fr: "Francúzsky",
  cz: "Česky",
  hu: "Maďarsky",
  ru: "Rusky",
  settings: "Nastavenia",
  languages: "Hovorené Jazyky",
  pricing: {
    regardlessGuestCount: "bez ohľadu na počet hostí",
    byNight: "/ noc",
    night: "noc",
    nightFrom: "od noci",
    pricePerNight: "cena za noc",
    guest: "hosť",
    guestCountLabel1: "%{value} hosť",
    guestCountLabel2: "od %{value} hostí",
    nightCountLabel1: "%{value} noc",
    nightCountLabel2: "od %{value} nocí",
    nightCountLabel3: "od %{value} nocí",
    cleaningPrice: "Poplatok za Upratovanie",
  },
  apartAccomodation: {
    basePriceSettings: "Základné Nastavenia",
    pricingRulesDelete: "Odstrániť",
    pricingRules: "Cenové Pravidlá",
    volumeDiscountPricing: "Zľava za Objem",
    guestDiscountPricing: "Zľava pre Hostí",
    discount: "Zľava",
    cleaningPrice: "Poplatok za Upratovanie",
    reservationLength: "Dĺžka Rezervácie",
    night: "Noc",
    nightFrom: "Od Noci",
    pricePerNight: "Cena za Noc",
    guest: "Hosť",
    currency: "Mena",
    pricing: "Nastavenie Cien",
    pricingTemplate: "Denné Ceny",
    priceVisible: "Ceny Publikované",
    noBedroom: "Žiadna Spálňa",
    noLivigingroom: "Žiadna Obývačka",
    noBathroom: "Žiadna Kúpeľňa",
    created: "Vytvorené",
    updated: "Aktualizované",
    settings: "Nastavenia",
    finalization: "Finalizácia",
    enabled: "Publikované",
    updated: "Aktualizované",
    deleted: "Odstránené",
    nextToImages: "Ďalej",
    add: "Pridať Nový Inzerát",
    modify: "Upraviť Inzerát",
    address: "Adresa Ubytovania",
    name: "Názov Ubytovania",
    description: "Popis",
    accomodationType: "Typ Ubytovania",
    house: "Dom",
    apartment: "Apartmán",
    room: "Izba",
    livingRoom: "Obývačka",
    kitchen: "Vybavená Kuchyňa",
    wifi: "Wifi",
    hairDryer: "Sušič na Vlasy",
    tv: "TV",
    amenities: "Vybavenie",
    services: "Služby",
    features: "Vlastnosti",
    pool: "Bazén",
    freeParking: "Bezplatné Parkovanie",
    gym: "Posilňovňa",
    location: "Poloha",
    beachfront: "Plážový",
    beachNearby: "V Blízkosti Pláže",
    restaurantsNearby: "V Blízkosti Reštaurácií",
    bustopNearby: "V Blízkosti Zastávky Autobusu",
    aiportNearby: "V Blízkosti Letiska",
    shopsNearby: "V Blízkosti Obchodov",
    balcony: "Balkón",
    airConditioner: "Klimatizácia",
    seeview: "Výhľad na More",
    lakeside: "Pri Jazere",
    mountainview: "Výhľad na Hory",
    downtown: "Centrum Mesta",
    countryside: "Vidiek",
    forestEdge: "Okraj Lesa",
    golfCourseAdjacent: "Golfové Ihrisko",
    massage: "Masáž",
    sauna: "Sauna",
    pool: "Bazén",
    heatedPool: "Vyhrievaný Bazén",
    lift: "Výťah",
    ground: "Prízemie",
    barrierFree: "Bez Bariér",
    smartTv: "Smart TV",
    netflix: "Netflix",
    bedRoom: "Spálne",
    bed: "Lôžko",
    bedroom: "Spálňa",
    bathroom: "Kúpeľňa",
    studio: "Štúdio",
    images: "Obrázky",
    addImages: "Pridať Obrázky",
    breakfastIncluded: "Raňajky v cene",
    lunchIncluded: "Obed v cene",
    dinnerIncluded: "Večera v cene",
    freeTransfer: "Bezplatný Transfer z Letiska",
    babyBed: "Detská Postieľka",
    petsAllowed: "Domáce Zvieratá Povolené",
    welcomeDrink: "Uvítací Nápoj",
    imageCategories: {
      rooms: "Izby",
      bathRoom: "Kúpeľňa",
      kitchen: "Kuchyňa",
      balkony: "Balkón",
      outdoor: "Exteriér",
    },
  },
  validation: {
    required: "Povinné Pole",
    selectionIsRequired: "Výber je Povinný",
  },
  typeMessage: "Správa...",
};
