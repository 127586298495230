import { ENDPOINT_COLLECTION_PATH as END } from "~src/constants";
import * as yup from "yup";

const accomodationCollection = ({ i18n }) => {
  const selectionIsRequiredRadio = () => {
    return yup.string().required(i18n.t("validation.selectionIsRequired"));
  };

  const selectionIsRequiredArray = () => {
    return yup
      .array()
      .min(1, i18n.t("validation.selectionIsRequired"))
      .required(i18n.t("validation.selectionIsRequired"));
  };

  return {
    _: {
      [`userUid${END}`]: { qString: "type=string" },
      [`dateInserted${END}`]: {
        qString: "type=date",
      },
      [`dateUpdated${END}`]: {
        qString: "type=date",
      },
      [`dateDeleted${END}`]: {
        qString: "type=date",
      },
      [`dateApproved${END}`]: {
        qString: "type=date",
      },
      [`state${END}`]: {
        qString: "type=string",
      },
      [`test${END}`]: {
        qString: "type=bool",
      },
    },
    basic: {
      [`address${END}`]: {
        i18: "address",
        validation: () => yup.object().required(i18n.t("validation.required")),
        qString: "type=address",
      },
      [`name${END}`]: {
        i18: "name",
        qString: "type=string",
        validation: () => yup.string().required(i18n.t("validation.required")),
      },
      [`type${END}`]: {
        i18: "accomodationType",
        icon: "home-account",
        qString: "type=[]&maxLength=1",
        // range: ['apartment', 'studio', 'house', 'room'],
        // range: [1, 2, 3, 4, 5, { option: 0, i18: "noBedroom" }],****
        range: [
          { option: "apartment", i18: "apartment", icon: "home-account" },
          { option: "studio", i18: "studio", icon: "home-account" },
          { option: "house", i18: "house", icon: "home-account" },
          { option: "room", i18: "room", icon: "home-account" },
        ],
        validation: () => selectionIsRequiredRadio(),
      },
    },
    advanced: {
      [`location${END}`]: {
        qString: "type=[]",
        i18: "location",
        icon: "palm-tree",
        range: [
          { option: "beachfront", i18: "beachfront", icon: "beach" },
          { option: "beachNearby", i18: "beachNearby", icon: "beach" },
          { option: "restaurantsNearby", i18: "restaurantsNearby", icon: "food-fork-drink" },
          { option: "seeview", i18: "seeview", icon: "beach" },
          { option: "bustopNearby", i18: "bustopNearby", icon: "rickshaw" },
          { option: "aiportNearby", i18: "aiportNearby", icon: "airplane" },
          { option: "shopsNearby", i18: "shopsNearby", icon: "shopping-outline" },
          { option: "mountainview", i18: "mountainview" },
          { option: "downtown", i18: "downtown", icon: "city" },
          { option: "countryside", i18: "countryside", icon: "nature" },
          { option: "forestEdge", i18: "forestEdge", icon: "forest" },
          { option: "golfCourseAdjacent", i18: "golfCourseAdjacent", icon: "golf" },
          { option: "lakeside", i18: "lakeside", icon: "beach" },
        ],
        validation: () => selectionIsRequiredArray(),
      },
      roomsAndBeds: {
        [`bedroom${END}`]: {
          qString: "type=[]&maxLength=1",
          i18: "bedroom",
          range: [1, 2, 3, 4, 5, { option: 0, i18: "noBedroom" }],
          icon: "power-sleep",
          validation: () => selectionIsRequiredRadio(),
        },
        [`livingRoom${END}`]: {
          qString: "type=[]&maxLength=1",
          i18: "livingRoom",
          range: [1, 2, { option: 0, i18: "noLivigingroom" }],
          icon: "white-balance-sunny",
          validation: () => selectionIsRequiredRadio(),
        },
        [`bed${END}`]: {
          qString: "type=[]&maxLength=1",
          i18: "bed",
          range: [1, 2, 3, 4, 5, 6, 7, 8, 9],
          icon: "bed",
          validation: () => selectionIsRequiredRadio(),
        },
        [`bathroom${END}`]: {
          qString: "type=[]&maxLength=1",
          i18: "bathroom",
          range: [1, 2, 3, { option: 0, i18: "noBathroom" }],
          icon: "bathtub",
          validation: () => selectionIsRequiredRadio(),
        },
      },
      [`features${END}`]: {
        qString: "type=[]",
        i18: "features",
        range: [
          { option: "wifi", i18: "wifi", icon: "wifi" },
          { option: "kitchen", i18: "kitchen" },
          { option: "balcony", i18: "balcony" },
          { option: "airConditioner", i18: "airConditioner", icon: "air-conditioner" },
          { option: "lift", i18: "lift", icon: "elevator-passenger-outline" },
          { option: "hairDryer", i18: "hairDryer" },
          { option: "ground", i18: "ground" },
          { option: "tv", i18: "tv", icon: "television" },
          { option: "smartTv", i18: "smartTv", icon: "television" },
          { option: "barrierFree", i18: "barrierFree" },
          { option: "babyBed", i18: "babyBed" },
          { option: "petsAllowed", i18: "petsAllowed", icon: "dog" },
        ],
        validation: () => selectionIsRequiredArray(),
      },
      [`services${END}`]: {
        qString: "type=[]",
        i18: "services",
        icon: "dots-grid",
        range: [
          { option: "freeTransfer", i18: "freeTransfer" },
          { option: "welcomeDrink", i18: "welcomeDrink" },
          { option: "pool", i18: "pool" },
          { option: "heatedPool", i18: "heatedPool" },
          { option: "freeParking", i18: "freeParking" },
          { option: "gym", i18: "gym" },
          { option: "massage", i18: "massage" },
          { option: "sauna", i18: "sauna" },
          { option: "breakfastIncluded", i18: "breakfastIncluded" },
        ],
      },
      [`description${END}`]: {
        i18: "description",
        qString: "type=string",
        icon: "card-text-outline",
        validation: () => yup.string().required(i18n.t("validation.required")),
      },

      bookingOptions: {
        [`instantBook${END}`]: { qString: "type=bool" },
        [`selfCheckIn${END}`]: { qString: "type=bool" },
        [`allowsPets${END}`]: { qString: "type=bool" },
      },
    },
    media: {
      images: {
        [`outdoor${END}`]: { qString: "type=image" },
        [`rooms${END}`]: { qString: "type=image" },
        [`kitchen${END}`]: { qString: "type=image" },
        [`bathRoom${END}`]: { qString: "type=image" },
        [`balkony${END}`]: { qString: "type=image" },
      },
    },
    settings: {
      [`enabled${END}`]: {
        qString: "type=bool",
      },
    },
    [`pricing${END}`]: {
      qString: "type=pricing",
    },
  };
};

export default accomodationCollection;
