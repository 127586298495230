// import * as Location from "expo-location";
import { ENDPOINT_COLLECTION_PATH as END } from "~src/constants";
// import * as ImageManipulator from "expo-image-manipulator";
import dayjs from "dayjs";


export const dateToDisplay = (date) => {
  return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
};

export const dateToMMMMDHHmm = (date) => {
  return dayjs(date).format("MMMM D, HH:mm");
};

async function getLocation() {
  let { status } = await Location.requestForegroundPermissionsAsync();
  if (status !== "granted") {
    alert("Permission to access location was denied");
    return;
  }

  let location = await Location.getCurrentPositionAsync({});

  return location;
}

export { getLocation };

export const arrayRandomItem = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

export const collectPaths = (obj, prefix = "", filter = null) => {
  const filterMatches = (path) => {
    if (!filter) {
      return true;
    }
    const arr = filter.split(".");
    if (arr.length > 1) {
      arr.forEach((filter_) => {
        if (path.includes(filter_)) {
          return true;
        }
      });

      return false;
    }

    return path.includes(filter);
  };
  let paths = [];

  Object.entries(obj).forEach(([key, value]) => {
    const path = `${prefix}${prefix ? "." : ""}${key}`;

    if (!filterMatches(path)) {
      return;
    }
    if (key.match(END)) {
      paths.push({
        path: path.replace(END, ""),
        config: value,
        value: null,
        name: key.replace(END, ""),
      });
      return;
    }

    const name = key;

    if (value && typeof value === "object" && !Array.isArray(value)) {
      paths = paths.concat(collectPaths(value, path, filter));
    } else {
      paths.push({ path, value, name, config: null });
    }
  });

  return paths;
};

export const capitalizeWord = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const setObjectValueByPath = (obj, path, value) => {
  const keys = path.split(".");
  let current = obj;
  keys.slice(0, -1).forEach((key, index) => {
    if (!current[key] || typeof current[key] !== "object") {
      current[key] = {};
    }
    current = current[key];
  });
  current[keys[keys.length - 1]] = value;
};

export const moveElement = (array, sourceIndex, targetIndex) => {
  if (sourceIndex >= array.length || targetIndex >= array.length) {
    return array;
  }

  const element = array.splice(sourceIndex, 1)[0];
  array.splice(targetIndex, 0, element);

  return array;
};

export const getUniqueFileName = (prefix = "", index = null) => {
  const now = new Date();
  const timestamp = now
    .toISOString()
    .replace(/[^0-9]/g, "")
    .slice(0, -5);

  const suffix =
    index !== null
      ? String(index).padStart(4, "0")
      : String(Math.floor(Math.random() * 1000)).padStart(4, "0");

  return `${prefix}_${timestamp}_${suffix}`;
};


export const getValueFromPath = (obj, path) => {
  const keys = path.split(".");
  return keys.reduce((currentObject, key) => {
    return currentObject && currentObject[key] !== undefined ? currentObject[key] : undefined;
  }, obj);
};

export const qStringToObj = (qString) => {
  const params = new URLSearchParams(qString);
  const obj = Array.from(params.entries()).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  return obj;
};

export const getNodeByPath = (collection, path) => {
  const node = collectPaths(collection, "", "").find((item) => {
    return item.path === path;
  });

  return node;
};

export const initialValues = (collection, data) => {
  let initialValues = {};
  collectPaths(collection, "").forEach((item) => {
    const { name, path } = item;
    const value = getValueFromPath(data, path);
    initialValues[name] = value ?? item?.config?.value;
  });

  return { ...initialValues };
};

export const initialValuesMySeeker = (collection, data) => {
  const initalValues_ = initialValues(collection, data);
  const displayName = data?.providerData?.displayName
    ? data.providerData.displayName.split(" ")
    : null;

  initalValues_.firstName =
    initalValues_.firstName ?? (displayName?.length > 0 ? displayName[0] : null);
  initalValues_.lastName =
    initalValues_.lastName ?? (displayName?.length > 1 ? displayName[1] : null);

  return { ...initalValues_ };
};

export const uniqueArr = (arr) => {
  return arr.reduce((accumulator, current) => {
    if (!accumulator.includes(current)) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);
};

export const processImages = (images, maxImage = null) => {
  const result = Object.values(images)
    .flatMap((cat) => cat?.map((imageItem) => imageItem))
    .filter((imageItem) => imageItem)
    .sort((a, b) => {
      if (a.indexImage === true) {
        return -1;
      }
      if (b.indexImage === true) {
        return 1;
      }
      return 0;
    })
    .map((imageItem) => {
      return imageItem.downloadUrl;
    });

  return maxImage > 0 ? (result.length > maxImage ? result.splice(0, maxImage) : result) : result;
};

export const easyId = (id, l = 3) => {
  return id.substr(id.length - l);
};

export const sleep = async (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
