import { createSlice } from "@reduxjs/toolkit";
import { fetchSeekListActionExtraReducer } from "./fetchSeekListAction";
import { fetchAdvertisersExtraReducer } from "./fetchAdvertisersAction";
import { fetchAccomodationDetailExtraReducer } from "./fetchAccomodationDetailAction";
import { fetchAccomodationDetailByUidsExtraReducer } from "./fetchAccomodationDetailByUidsAction";
import { SEEK_EVENT_INITIALIZE } from "~src/constants";

const initialState = {
  seekData: null,
  accomodationDetail: null,
  accomodationsDetail: null,
  lastVisible: null,
  selectedScrollItem: null,
  selectedScrollItemIndex: null,
  cursor: null,
  seekDataTest: null,
  radius: 20000,
  firstItemsCount: 10,
  limit: 300,
  region: {
    latitude: 28.2362137,
    longitude: -16.8291,
    latitudeDelta: 0.2,
    longitudeDelta: 0.2,
  },
  seekEvent: SEEK_EVENT_INITIALIZE,
  controllerEvent: null,
  mapEvent: null,
  scrollerEvent: null,
  selectedAccomodation: null,
};

const seekSlice = createSlice({
  name: "seekSlice",
  initialState,
  reducers: {
    setSelectedScrollItem: (state, action) => {
      if (action.payload) {
        const { location } = action.payload.item.basic.address;
        const region = {
          latitude: location.lat,
          longitude: location.lng,
          latitudeDelta: state.region.latitudeDelta,
          longitudeDelta: state.region.longitudeDelta,
        };
        state.region = { ...region };
      }
      state.selectedScrollItem = action.payload;
    },
    setSelectedScrollItemIndex: (state, action) => {
      state.selectedScrollItemIndex = action.payload;
    },
    setCursor: (state, action) => {
      state.cursor = action.payload;
    },
    resetSeekData: (state) => {
      state.seekData = null;
      state.lastVisible = null;
      state.selectedScrollItem = null;
    },
    setSeekData: (state, action) => {
      state.seekData = action.payload;
      state.lastVisible = null;
      state.selectedScrollItem = null;
    },
    resetRegion: (state) => {
      state.region = initialState.region;
    },
    setRegion: (state, action) => {
      // console.log(JSON.stringify(`ACTION::: ${action.payload.latitude}`, null, 2));
      state.region = action.payload;
    },
    setSeekEvent: (state, action) => {
      state.seekEvent = action.payload;
    },
    setSelectedAccomodation: (state, action) => {
      state.selectedAccomodation = action.payload;
    },
    setAccomodationDetail: (state, action) => {
      state.accomodationDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    fetchSeekListActionExtraReducer(builder);
    fetchAdvertisersExtraReducer(builder);
    fetchAccomodationDetailExtraReducer(builder);
    fetchAccomodationDetailByUidsExtraReducer(builder);
  },
});

export const {
  setSeekEvent,
  resetRegion,
  setSelectedScrollItem,
  setSelectedScrollItemIndex,
  resetSeekData,
  setSeekData,
  setCursor,
  setRegion,
  setSelectedAccomodation,
  setAccomodationDetail,
  
} = seekSlice.actions;
export { seekSlice };
