export default {
  loginScreenEmailPassword: "Bejelentkezés",
  loginEmailPwButton: "Bejelentkezés e-mail és jelszó megadásával",
  email: "E-mail",
  loginButton: "Bejelentkezés",
  password: "Jelszó",
  createAccount: "Fiók létrehozása",
  forgotPassword: "Elfelejtett jelszó",
  resetPasswordButton: "Jelszó visszaállítása",
  resetPasswordScreen: "Elfelejtett jelszó",
  resetPasswordMessage: "Jelszó visszaállítási linket küldtünk az e-mail címére",
  providedEmailWasNotFound: "A megadott e-mail cím nem található",
  loginFailed: "Sikertelen bejelentkezés",
  emailExists: "Ez a fiók már létezik",
  invalidEmailAddress: "Érvénytelen e-mail cím",
  invalidPassword: "A jelszónak legalább 6 karakter hosszúnak kell lennie",
  verificationSent: "Megerősítő e-mailt küldtünk. Kérjük, ellenőrizze az e-mailjeit, és erősítse meg a regisztrációját.",
  accountInactive: "Fiókja még inaktív",
  privacyPolicyButton: "Olvassa el adatvédelmi szabályzatunkat",
  unknown: "Ismeretlen felhasználó",
  youMustBeLoggedIn: "A funkció eléréséhez be kell jelentkezned.",
  youMustBeLoggedInToPost: "Hirdetés feladásához be kell jelentkezned.",
  
  aboutUs: 'apartseeker.com',
  seekDetailScreenTitle: "Szállás részletek",
  deleteTopicMessage: "Törlöd ezt a beszélgetést az összes üzenetével együtt?",
  chatScreenTitle: "Üzenetek",
  seekListScreenTitle: "Felfedezés",
  seek: "Felfedezés",
  seekAndEnjoy: "",
  emailVisible: "E-mail publikus",
  firstName: "Keresztnév",
  lastName: "Vezetéknév",
  home: "Nyitólap",
  welcome: "Üdvözlet",
  myAds: "Hirdetéseim",
  myAparts: "Apartmanok",
  myApartsShort: "Apart.",
  login: "Belépés",
  tests: "Tesztek",
  examples: "Példák",
  searchAddress: "Cím keresése",
  save: "Mentés",
  cancel: "Mégsem",
  back: "Vissza",
  delete: "Töröl",
  forward: "Tovább",
  select: "Válassz",
  profileAdvSettings: "Hirdetői profil beállítása",
  validationMinus: "Az érték nem lehet negatív",
  validation0100: "Az érték 0 és 100 között lehet",
  validationPrice1: "Az ár megadása kötelező",
  validationPrice2: "Az ár hibásan van megadva",
  validationRequired: "Kötelező megadni",
  validationPrevValue: "Nagyobnnak kell lenni mint {{value}}",
  created: "Létrehozva",
  updated: "Módosítva",
  loginFacebook: "Belépés Facebookkal",
  profileNormalMenu: "Beállítások",
  profileAdvMenu: "Hirdetőknek",
  profileScreenTitle: "Beállítások",
  profileAdvScreenTitle: "Hirdetői profil",
  AdvertiserMenu: "Hirdetőknek",
  account: "Fiók",
  profile: "Profil",
  explore: "Felfedezés",
  
  iAmAdvertiser: "Hirdető vagyok",
  approveTerms: "A felhasználási feltételeket elolvastam és elfogadom",
  approveTermsDesc: "Hirdetéseid addig nem jelennek meg amíg nem fogadod el a feltételeket.",
  availableAccomodations: "Még feladhatsz %{value} hirdetést",
  noAvailableAccomodations: "Nem adhatsz fel több hirdetést",
  approveTermsIsRequired: "Hirdetés feladáshoz fogadd el a felhasználási feltételeket.",
  search: "Keresés",
  searchScreenTitle: "Keresés",

  searchByAddress: "Cím",
  searchByMap: "Térkép",
  searchFavourites: "Kedvencek",
  searchHistory: "Előzmények",
  logout: 'Kilépés',
  deleteSeeker: "Felhasználói fiókom törlése",
  areYouSureDeleteSeeker: "A felhasználói fiókod és a hozzá kapcsolódó összes adat törlésre kerül. Biztosan folytatod?", 
  areYouSureDeleteAccomodation: "A hirdetésed törlésre kerül. Biztosan folytatod?", 
  deleteAccomodation: "Hirdetés törlése",
  pageNotFoundTitle: "404",
  pageNotFoundText: "Amit keresel jelenleg nem elérhető, gyere vissza később.",
  emptyListText: "Nincsenek megjeleníthető elemek",
  seekListEmptyText: "Még nincsenek szállások ezen a területen.",
  seekListEmptyButtonText: "Ha hirdetni akarsz, adj fel hirdetést itt",
  chatEmptyText: "Még nincsenek üzeneteid. Vedd fel a kapcsolatot a hirdetőkkel! Ezt könnyen megteheted a hirdetéseknél található ikon segítségével.",
  signupDesc:
    "Ahhoz hogy közzé tudd tenni a hirdetést, el kell fogadnod a felhasználási feltételeket.",
  advPackages: {
    myPackage: "Csomagom",
    general: "Jelenleg csak az alap hirdetői csomag érhető el",
    maxAccomodation: "Feladható hirdetések száma %{value}",
    maxItem: "Feladható hirdetések száma %{value}",
    maxImagesPerCategory: "Feltölthető képek száma összesen %{value}",
    maxPriceRules: "Alkalmazható árszabályok száma %{value}",
    durationDay: "Érvényes %{value} napig, ingyenesen meghosszabítható",
    default: {
      title: "Alapcsomag",
      price: "Ingyenes", 
    },
  },
  accomodationDefaultPackageName: "Alap hirdetői csomag",
  phone: "Telefon",
  language: "Nyelv",
  appLanguage: "Alkalmazás nyelve",
  en: "Angol",
  de: "Német",
  es: "Spanyol",
  fr: "Francia",
  cz: "Cseh",
  hu: "Magyar",
  ru: "Orosz",
  settings: "Beállítások",
  languages: "Beszélt nyelvek",
  pricing: {
    regardlessGuestCount: "a vendégek számától függetlenül",
    byNight: "/ éj",
    night: "éj",
    nightFrom: "éjtől",
    pricePerNight: "ár éjszakánként",
    guest: "vendég",
    guestCountLabel1: "%{value} vendég",
    guestCountLabel2: "%{value} vendégtől",
    nightCountLabel1: "%{value} éjszaka",
    nightCountLabel2: "%{value} éjszakától",
    nightCountLabel3: "%{value} éjtől",
    cleaningPrice: "Takarítási díj",
  },
  apartAccomodation: {
    basePriceSettings: "Alapbeállítások",
    pricingRulesDelete: "Töröl",
    pricingRules: "Árszabályok",
    volumeDiscountPricing: "Árszabály",
    guestDiscountPricing: "Árszabály a vendégek száma alapján",
    discount: "Kedvezmény",
    cleaningPrice: "Takarítási díj",
    reservationLength: "Foglalás hossza",
    night: "Éj",
    nightFrom: "Éjtől",
    pricePerNight: "Ár éjszakánként",
    guest: "Vendég",
    currency: "Pénznem",
    pricing: "Árbeállítás",
    pricingTemplate: "Napi árak",
    priceVisible: "Az árak közzétéve",
    noBedroom: "Nincs hálószoba",
    noLivigingroom: "Nincs nappali",
    noBathroom: "Nincs fürdőszoba",
    created: "Létrehozva",
    updated: "Módosítva",
    settings: "Beállítások",
    finalization: "Véglegesítés",
    enabled: "Közzétéve",
    updated: "Módosítva",
    deleted: "Törölve",
    nextToImages: "Tovább",
    add: "Új hirdetés",
    modify: "Hirdetés módosítása",
    address: "Szállás címe",
    name: "Szállás neve",
    description: "Leírás",
    accomodationType: "Szállás típusa",
    house: "Ház",
    apartment: "Apartman",
    room: "Szoba",
    livingRoom: "Nappali",
    kitchen: "Felszerelt konyha",
    wifi: "Wifi",
    hairDryer: "Hajszárító",
    tv: "TV",
    amenities: "Kényelmi szolgáltatások",
    services: "Szolgáltatások",
    features: "Jellemzők",
    pool: "Medence",
    freeParking: "Díjmentes parkolás",
    gym: "Edzőterem",
    location: "Elhelyezkedés",
    beachfront: "Tengerparti",
    beachNearby: "Strand közelében",
    restaurantsNearby: "Éttermek közelében",
    bustopNearby: "Buszmegálló közelében",
    aiportNearby: "Repülőtér közelében",
    shopsNearby: "Boltok közelében",
    balcony: "Erkély",
    airConditioner: "Légkondicionált",
    seeview: "Tengerre néző",
    lakeside: "Tóparti",
    mountainview: "Hegyre néző",
    downtown: "Belváros",
    countryside: "Vidék",
    forestEdge: "Erdőre néző",
    golfCourseAdjacent: "Golfpálya",
    massage: "Masszázs",
    sauna: "Szauna",
    pool: "Medence",
    heatedPool: "Fűtött medence",
    lift: "Lift",
    ground: "Földszint",
    barrierFree: "Akadálymentesített",
    smartTv: "Okos TV",
    netflix: "Netflix",
    bedRoom: "Hálószobák",
    bed: "Férőhely",
    bedroom: "Hálószoba",
    bathroom: "Fürdőszoba",
    studio: "Stúdió",
    images: "Képek",
    addImages: "Képek hozzáadása",
    breakfastIncluded: "Reggeli az árban",
    lunchIncluded: "Ebéd az árban",
    dinnerIncluded: "Vacsora az árban",
    freeTransfer: "Ingyenes reptéri transzfer",
    babyBed: "Babaágy",
    petsAllowed: "Kisállatbarát",
    welcomeDrink: "Üdvözlőital",
    imageCategories: {
      rooms: "Szobák",
      bathRoom: "Fürdőszoba",
      kitchen: "Konyha",
      balkony: "Erkély",
      outdoor: "Környezet",
    },
  },
  validation: {
    required: "Kötelező mező",
    selectionIsRequired: "Kötelező választani",
  },
  typeMessage: "Üzenet...",
};
