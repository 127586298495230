// import { accomodationAdminSlice } from "./accomodatation-admin/accomodationAdminSlice";
// import { authSlice } from "./auth/authSlice";
import { progressSlice } from "./progress/progressSlice";
 import { configureStore } from "@reduxjs/toolkit";
import { seekSlice } from "./seek/seekSlice";
import { seekerSlice } from "./seeker/seekerSlice";
// import { chatSlice } from "./chat/chatSlice";
// import { searchSlice } from "./search/searchSlice";
// import { advPackageSlice } from "./adv-package/advPackageSlice";

export const store = configureStore({
  reducer: {
    // auth: authSlice.reducer,
    // accomodationAdmin: accomodationAdminSlice.reducer,
    progress: progressSlice.reducer,
    seek: seekSlice.reducer,
    // chat: chatSlice.reducer,
    seeker: seekerSlice.reducer,
    // search: searchSlice.reducer,
    // advPackage: advPackageSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
