import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import Contact from "./screens/Contact";
import "./App.css";
import PrivacyPolicy from "~src/screens/PrivacyPolicy";
import TermsOfService from "~src/screens/TermsOfService";
import UserAccountDeletion from "~src/screens/UserAccountDeletion";
import AppStore from "~src/screens/AppStore";
import SeekDetailScreen from "./screens/seek/detail/SeekDetailScreen";
import { firebaseApp } from "./config/firebase";
import { Provider } from "react-redux";
import { store } from "~src/store/store";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <div>
            {/* <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav> */}

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/user-account-deletion" element={<UserAccountDeletion />} />
              <Route path="/app-store" element={<AppStore />} />
              <Route path="/accommodation/:accommodation_id" element={<SeekDetailScreen />} />
            </Routes>
          </div>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
