import styled from "styled-components";

export const View = styled.div(
  ({ theme }) => `
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: ${theme.primaryContainer};
`
);

export const TitleH1 = styled.h1(({ theme }) => `
    color: ${theme.titleColor}; 
    display: block;
    `
);

export const LoadingContainer = styled.div(
  ({ theme }) => `
  color: #000000; 
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 50vh;
  background-color: ${theme.surfaceDisabled};
  color: ${theme.titleColor}
  `
);

export const Text = styled.span``;
export const Badge = styled.span``;
export const Icon = styled.span``;
