import { createAsyncThunk } from "@reduxjs/toolkit";
import { firebaseDB, firebaseApp } from "~src/config/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  startAfter,
  startAt,
  endAt,
} from "firebase/firestore";

import { setLoading } from "../progress/progressSlice";

export const fetchAdvertisersAction = createAsyncThunk(
  "seek/fetchAdvertisersAction",
  async ({ advertiserUids }, { dispatch, rejectWithValue, getState }) => {
    dispatch(setLoading(true));
    try {
      let snapshot = await getDocs(
        query(collection(firebaseDB, "seekers"), where("__name__", "in", advertiserUids))
      );

      return snapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const fetchAdvertisersExtraReducer = (builder) => {
  builder.addCase(fetchAdvertisersAction.fulfilled, (state, action) => {
    console.log("fetchAdvertisersAction fulfilled");

    state.advertiserData = action.payload;
  });

  builder.addCase(fetchAdvertisersAction.rejected, (state, action) => {
    throw action.payload;
  });
};
