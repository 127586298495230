import { createAsyncThunk } from "@reduxjs/toolkit";
import { firebaseDB } from "~src/config/firebase";
import { getDoc, setDoc, doc, updateDoc } from "firebase/firestore";
import { setLoading } from "../progress/progressSlice";

export const insertUpdateMySeekerAction = createAsyncThunk(
  "seeker/insertUpdateMySeekerAction",
  async ({ userUid, payload }, { dispatch, rejectWithValue, getState }) => {
    try {
      const docRef = doc(firebaseDB, "seekers", userUid);
      let docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, payload);
        docSnap = await getDoc(docRef);

        return docSnap.data();
      } else {
        await setDoc(docRef, payload);
        return payload;
      }
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const insertUpdateMySeekerExtraReducer = (builder) => {
  builder.addCase(insertUpdateMySeekerAction.fulfilled, (state, action) => {
    // console.log("insertUpdateMySeekerAction fulfilled");
    state.mySeekerData = action.payload;
  });

  builder.addCase(insertUpdateMySeekerAction.rejected, (state, action) => {
    throw action.payload;
  });
};
